import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { Comic } from '../models/comic';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { StorageService } from './storage/storage.service';
import { formatDistanceStrict, } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { API_KEYS, BROWSER as browser, latestComics, LOGIN_FLOW, REFERRAL_CONSTS, stylesJson, subscribeURL, templateTypes, youtubeDomains } from '../constants/common.constants';
import { subscriptionLevels, subscriptionLevelsFull } from '../constants/subscription.constants';
import * as util from '../utilities/common.util';
import { shareConfig } from '../constants/influence.constants';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { ModalService } from './modal.service';
import { isRecordNotFound } from '../utilities/error.util';
import { EventService } from './event.service';
import { isAnonymousUser, isComicTimeRecent } from '../utilities/common.util';
import { CacheService, SERIES_LIST_DATA } from './cache.service';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { StoryFeedService } from './story-feed.service';

const CDN_BASE_URL = `${environment.CDN_BASE_URL}`;
const COMIC_API = `${environment.API_END_POINT}`;
const switch_app_link = `${environment.SWITCH_APP_LINK}`
const BROWSER = browser;
const DOMAIN_NAME = `${environment.DOMAIN_NAME}`
const IMAGE_END_POINT = `${environment.IMAGE_END_POINT}`;

@Injectable({
  providedIn: 'root'
})
export class ComicService {
  public userLikesArray: any;
  public chapterListData: any;
  admins: any = [];
  showShare: boolean = false;
  adminarray: any = [];
  admin: any[];
  directoryCache: Object = {};
  public cacheComic: any = {};
  public scrollValue: any = {};
  pageInfo: firebase.functions.HttpsCallableResult;
  cacheOfPageInfo: any = {};
  isAnalysts: boolean;
  comicTitle: any;
  tinyviewAdmin: boolean;
  isAdmin: boolean;
  seriesTitle: any;
  currentDevice: string;
  comicAction: any;
  isDone: boolean;
  productIDs$: Subject<any> = new Subject<any>();
  showAllComics$: Subject<any> = new Subject<any>();
  followedComic$: Subject<any> = new Subject<any>();
  storyWriterComics$: Subject<any> = new Subject<any>();
  commentCounts$: Subject<any> = new Subject<any>();
  resendOTPError: Subject<any> = new Subject<string>();
  infiniteScroll: Subject<any> = new Subject<any>();

  urlParams: any
  public editPanel: any = [];
  public deletePanel: any = []
  userFollowedComics: any = [];
  isFollowedComic: boolean = false;
  public showComments: boolean = false;
  public userDetails: any;
  public readonly $userDetails = new Subject<any>();
  public userLikesData: any;
  // public giftItems: any;
  public portalLink: string;
  public allProducts: Array<any> = [];
  public influencePoints: any;
  public pageLoadTrace: any;
  reactionNames = [];
  public directoryData: any;
  userRequiredAlerts = {}; // array contianing series for which user want alerts or series current user is following
  accessInfo: any;
  userDetailsPromise: any;
  private getComicChaptersObservable: Observable<any> | null = null;
  private getComicTitleCache: Observable<any> | null = null;
  private getUnlockedComicsURLsPromise: Promise<any> | null = null;
  private getPageInfoPromise: Promise<any> | null = null;

  constructor(
    private readonly http: HttpClient,
    private storageService: StorageService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platform: object,
    private readonly router: Router,
    private _clipboardService: ClipboardService,
    private toastr: ToastrService,
    private eventService: EventService,
    private cacheService: CacheService,
    private apiService: ApiService,
    private loggerService: LoggerService,
    private storyFeedService: StoryFeedService
  ) {
    this.currentDevice = this.getOperatingSystem();
    this.influencePoints = {
      total: 0,
      balance: 0,
      consume: 0
    }

    // Using events to resolve circular dependency issues
    // Listening all events here
    this.eventSubscriptions();
  }
  isVisibleSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasFooter: BehaviorSubject<string> = new BehaviorSubject('');
  public homePagePreview: any = {};

  // Use getShareData() function to get this dynamically.
  shareData = {
    title: 'Tinyview',
    url: 'https://tinyview.com' + this.router.url,
  };

  private eventSubscriptions() {
    // Listening for the clearUserDataEvent event
    this.eventService.clearUserDataEvent.subscribe(() => {
      this.clearUserData();
    });

    // Add more events here if needed
  }

  setInfiniteScroll(data: any) {
    this.infiniteScroll.next(data);
  }

  getInfiniteScroll() {
    return this.infiniteScroll.asObservable();
  }

  public getShareData() {
    return {
      title: 'Tinyview',
      url: 'https://tinyview.com' + this.router.url,
    };
  }

  public getStylesJson() {
    const url = `${COMIC_API}/tinyview/styles.json?${Date.now()}`;

    return this.http.get<any>(url).pipe(
      catchError((error) => {
        return of(stylesJson); // Return a fallback json style.
      }))
  }

  public getComicChapters(comicSlug: string, queryParams: string): Observable<any> {
    let url = `${COMIC_API}`;
    // Construct the URL
    if (queryParams) {
      url += comicSlug ? `${comicSlug}/${queryParams}.json` : `/${queryParams}.json`;
    } else {
      url += comicSlug !== '/' ? `${comicSlug}/index.json?` : `/index.json`;
      if (isPlatformBrowser(this.platform)) {
        var base64Url = btoa(url);
      }
      url += comicSlug !== '/' ? `${Date.now()}` : ``;
    }
  
    // Use the URL as a unique key for caching
    const cacheKey = btoa(url);
  
    // Check if the request is already in progress or cached
    if (!this.getComicChaptersObservable) {
      // Create the Observable
      this.getComicChaptersObservable = this.http.get<any>(url).pipe(
        map(result => {
          if (isPlatformBrowser(this.platform)) {
            this.directoryCache[cacheKey] = result; // Store the result in the cache
          }
          return result;
        }),
        shareReplay(1) // Share the result with all subscribers
      );
      setTimeout(() => {
        // Remove the Observable after completion
        this.getComicChaptersObservable = null;
      }, 1000)
    }
    // Return the Observable
    return this.getComicChaptersObservable;
  }

  // public getComicChaptersForEpisodes(comicSlug, queryParams): Observable<any> {
  //   let url = `${COMIC_API}`;
  //   if (queryParams) {
  //     url += comicSlug ? `${comicSlug}/${queryParams}.json` : `/${queryParams}.json`;
  //   } else {
  //     url += comicSlug != '/' ? `${comicSlug}/index.json?` : `/index.json`;
  //     url += comicSlug != '/' ? `${Date.now()}` : ``;
  //     return this.http.get<any>(url)
  //       .pipe(
  //         map(result => {
  //           return result;
  //         })
  //       );
  //   }
  // }

  public getSubscriptionPanel() {
    if (this.allProducts && this.allProducts.length) {
      return of(this.allProducts);
    }
    let url = `${COMIC_API}`;
    url += `/tinyview/subscribe/index.json`;
    return this.http.get<any>(url)
      .pipe(
        map(result => {
          this.allProducts = result.comics.panels;
          return this.allProducts;
        })
      );
  }

  public async getUpgradeSubscription(productId: string, newProductID: string) {
    const upgradeSubscriptions = await this.apiService.send(API_KEYS.UPDATE_SUBSCRIPTION);
    const res = await upgradeSubscriptions(
      {
        "productID": productId,  //existing subscription product ID
        "newProductID": newProductID //new subscription product ID
      });
    return res;
  }

  public async getCancelSubscription(productId: string) {
    const cancelSubscriptions = await this.apiService.send(API_KEYS.CANCEL_SUBSCRIPTION);
    const res = await cancelSubscriptions(
      {
        "productID": productId,  //existing subscription product ID
      });
    return res;
  }

  public async getSubscriptionMeterAggregates(url) {
    const getAggregates = await this.apiService.send(API_KEYS.GET_SUBSCRIPTION_METER_AGGREGATES);
    const current_url = url.replace('/', '')
    const res = await getAggregates({ scope: current_url });
    return res;
  }

  public async getUserDetails() {
    const res = await this.getUserDetailsV2();
    this.userDetails = res.data;

    if (this.userDetails.data.influencePoints) {
      this.influencePoints = this.userDetails.data.influencePoints;
    }

    if (
      this.userDetails &&
      this.userDetails.data &&
      this.userDetails.data.status &&
      this.userDetails.data.status.toLowerCase() === LOGIN_FLOW.MERGED.toLowerCase()
    ) {
      this.eventService.showLoggedOutModalEvent.emit({ userMerged: true });
    }
    return res.data;
  }
  
  public async getUserDetailsV2() {
    if (this.userDetailsPromise) {
      // Return the existing promise if an API call is already in progress
      return this.userDetailsPromise;
    }

    // Create a new promise for the API call
    this.userDetailsPromise = new Promise(async (resolve, reject) => {
      let attempts = 0;
      // To avoid delayed creation of user in User collection (addUser);
      while (attempts < 3) {
        try {
          const getUserDetails = await this.apiService.send(API_KEYS.GET_USER_DETAILS);
          const res = await getUserDetails();
          this.$userDetails.next(res.data);
          resolve(res);
          break;
        } catch (e) {
          attempts++; // Increment the attempt counter
          if (attempts >= 3) {
            console.log('comic service - getUserDetails - catch e', e);
            reject(e); // Reject all waiting promises with the error
          }
        }
      }
    });
    setTimeout(() => {
      this.userDetailsPromise = null;
    }, 1000)
    return this.userDetailsPromise;
  }

  // public async getUserLikes() {
  //   const userLikes = firebase.functions().httpsCallable('getUserLikes');
  //   const res = await userLikes();
  //   this.userLikesData = res.data;
  //   return res.data;
  // }

  public async getComicNavigation(input: any) {
    const getNavigation = await this.apiService.send(API_KEYS.GET_NAVIGATION);
    const res = await getNavigation({
      "forFeedPage": input.forFeedPage,
      "storyID": input.storyID || '',
      "series": input.series,
      "isContinueReading": input.isContinueReading,
      "action": input.action || ''
    });
    return res.data;
  }

  public async getEpisodesList(input: any) {
    const getEpisodes = await this.apiService.send(API_KEYS.GET_EPISODES);
    const res = await getEpisodes({
      // "data" : {
      "forFeedPage": input.forFeedPage,
      "storyID": input.storyID || '',
      "series": input.series,
      "action": input.action,
      "episodeID": input.episodeID
      // }
    });
    return res.data;
  }

  async getEpisodes(comicID?: string) {
    let isFromFollowing: boolean;
    let storyID: string;
    const episodeID = comicID;
    const currentUrl = this.getCurrentUrl();
    const action = `${currentUrl}/index.json`;
    const series = currentUrl.split('/')[1];
    const prevPage = this.localStorageService.getItem('selectedMenuItem');
    prevPage === 'following' ? isFromFollowing = true : isFromFollowing = false;
    storyID = this.localStorageService.getItem('storyID') || comicID;
    if (prevPage && prevPage !== 'following' && prevPage !== 'home' && !this.isComicPage()) {
      storyID = '';
    }
    const input = {
      forFeedPage: isFromFollowing,
      storyID,
      series,
      action,
      episodeID
    };
    const data = await this.getEpisodesList(input);
    return data
  }

  public async getCheckoutURL(pID, storyID?, reactionName?, value = 0) {
    if (storyID && pID && reactionName) {
      this.localStorageService.setItem('gift_payment', JSON.stringify({ storyID, reactionName, value }));
    }
    const reqObj = {
      productID: pID
    };
    if (storyID) reqObj['storyID'] = storyID;
    const getURL = await this.apiService.send(API_KEYS.CREATE_CHECKOUT_LINK);
    const res = await getURL(reqObj);
    return res;
  }

  public async createPortalLink() {
    if (this.portalLink) return this.portalLink;
    const portalLink = await this.apiService.send(API_KEYS.CREATE_PORTAL_LINK)
    const res = await portalLink({});
    this.portalLink = res.data.data.url;
    return this.portalLink;
  }

  // public async getGifts() {
  //   if (this.giftItems) return this.giftItems;
  //   const gifts = firebase.functions().httpsCallable('getGiftItem');
  //   const res = await gifts({});
  //   this.giftItems = res.data;
  //   this.reactionNames = this.giftItems.data.giftItems.map(item => item.reactionName);
  //   return this.giftItems;
  // }

  public getFooterChapters(comicSlug: string): Observable<any> {
    let url = `${COMIC_API}`;
    url += comicSlug + '?' + `${Date.now()}`;
    if (isPlatformBrowser(this.platform)) {
      var base64Url = btoa(url);
    }
    if (this.directoryCache[base64Url]) {
      if (isPlatformBrowser(this.platform)) {
        return of(this.directoryCache[base64Url])
      }
    } else {
      return this.http.get<any>(url)
        .pipe(
          map(result => {
            this.directoryCache[base64Url] = result;
            return result;
          })
        );
    }
  }


  public getCurrentUrl(): string {
    return this.router.url.split('?')[0];
  }

  public isCoverPage(getPath?, url?) {
    var arr;
    if (url) {
      let stack = url.split('/');
      stack.pop();
      arr = stack.splice(1);
      return (arr.length <= 1)
    }
    if (isPlatformBrowser(this.platform) && window && window['location']) {
      var pathName = window.location.pathname;
      pathName = pathName.slice(1);
      arr = pathName.split('/');
      if (getPath) {
        return arr[0];
      }
      return ((arr.length <= 1 || arr.length[2] == '') && window.location.pathname != '/story');
    }
  }

  public openInStore(storyID?: string, actionType?: string) {
    let tags = this.isCoverPage(true);
    if (tags == '') {
      tags = 'tinyview';
    }
    const linkData = {
      campaign: 'website',
      feature: 'open-in-app',
      channel: 'tinyview',
      tags: [tags],
      data: this.getBranchLinkData(true)
    };

    if (this.isStoryPage()) {
      storyID = this.getCurrentUrl() && this.getCurrentUrl().split('/').pop();
    }
    if (storyID) {
      linkData.data = {
        ...linkData.data,
        actionType: "story",
        storyID
      }
    }
    if (actionType) linkData.data['actionType'] = actionType;
    if (isPlatformBrowser(this.platform) && window && window['branch']) {
      window['branch'].link(linkData, (err, link) => {
        location.href = link;
      });
    }
  }

  // public sendMessage(linkData, linkOptions, phone) {
  //   const callback = (err, result) => {
  //     if (err) {
  //       alert('Something went wrong.');
  //     } else {
  //       alert('Text message was sent successfully.');
  //     }
  //   };
  //   if (isPlatformBrowser(this.platform) && window && window['branch']) {
  //     window['branch'].sendSMS(phone, linkData, linkOptions, callback);
  //   }
  // }

  // Dupe of comic service
  public getBranchLinkData(isOpenAppClick?:boolean): any {
    // const referredByCookie = this.cookieService.get('referred_by');
    if (isPlatformBrowser(this.platform)) {
      const referredByCookie = this.localStorageService.getItem('referred_by');
      let currentUrl = this.getCurrentUrl();
      if (currentUrl == '/') {
        currentUrl = ''
      }
      const res = {
        referred_by: referredByCookie,
        deeplink_path: `${COMIC_API}${currentUrl}/index.json`,
        $ios_url: 'https://apps.apple.com/us/app/tinyview-comics/id1478702420',
        $android_url: `https://play.google.com/store/apps/details?id=${switch_app_link}`
      };

      if ((["/tinyview/influence-points"].includes(this.getCurrentUrl()) || isOpenAppClick) && !this.isStoryPage()) { // isInfluencePointsPage, remove when it gets handled in app (bug in APP)
        res['deeplink_url'] = `${currentUrl}/index.json`;
      }

      return res;
    }
  }

  public isComicHomepage(comic: Comic): boolean {
    for (let i = 0; i < comic.panels.length; i += 1) {
      if (comic.panels[i].image &&
        !comic.panels[i].actionType) {
        return true;
      }
    }
    return false;
  }

  // Dupe of comic service
  public isComicPage(pathVal?): boolean {
    // SSR check
    if (typeof window === "undefined" && !pathVal) {
      return false;
    }
    // i.e path - "/heart-and-brain/2022/01/26/sleepless"
    const path = pathVal || location.pathname;
    if (!['/', '/tinyview/comic-series-directory'].includes(path) && !path.includes('/edit')
      && !this.isStoryPage() && path.split('/').length > 2) {
      return true;
    }
    return false
  }

  public isTinyviewPage(currentUrl: string): boolean {
    const tinyviewRegex = /^\/tinyview/;
    const isTinyView = tinyviewRegex.test(currentUrl);
    const isHomeOrFollowingOrNotification = ['', '/', '/notifications'].includes(currentUrl);
    const isTinyviewPage = isTinyView || isHomeOrFollowingOrNotification;
    return isTinyviewPage;
  }

  public isStoryPage(): boolean {
    const regexPattern = /\/story\/[a-zA-Z0-9]+/g;
    return regexPattern.test(this.getCurrentUrl());
    // return !this.isComicPage() && (this.getCurrentUrl().indexOf('story') > -1);
  }

  public async isSeriesPage() {
    const currentUrl = this.getCurrentUrl();
    let isSeriesPage = false;
    let seriesListData = this.cacheService.get(SERIES_LIST_DATA);
    if (!seriesListData) await this.getComicTitle().toPromise();
    seriesListData = this.cacheService.get(SERIES_LIST_DATA);
    if (!this.isTinyviewPage(currentUrl) && seriesListData) {
      if ([...seriesListData].includes(currentUrl)) {
        isSeriesPage = true;
      }
    }
    return isSeriesPage;
  }

  public isTinyviewSeriesPage() {
    return this.getCurrentUrl() === '/tinyview';
  }

  public formatSeriesName(series) {
    series = series == '/' || !series ? '' : series;
    return `${series.split('/').slice(0, 2).join('/')}/footer.json`
  }

  public formatSeriesHeaderName(series) {
    series = series == '/' || !series ? '' : series;
    return `${series.split('/').slice(0, 2).join('/')}/header.json`
  }

  public resolveJsonInAction(action) {
    if (action && action.indexOf('/index.json') != -1) {
      const stack = action.split('/');
      stack.pop();
      return stack.join('/');
    }
    const actionStack = action.split('/');
    const path = actionStack[actionStack.length - 1];
    const dotIndex = path.indexOf('.');
    const query = path.slice(0, dotIndex);
    let finalstack = actionStack.splice(0, actionStack.length - 1);
    const finalUrl = finalstack.join('/');
    return `${finalUrl}?fn=${query}`;
  }

  public async addRemoveLike(actionUrl, like?, fromAlerts?: boolean, meta?): Promise<any> {
    const userDetails = await this.getUserDetails();
    const hasAnyChannel = this.userHasAnyChannel(userDetails);
    if (!hasAnyChannel && like && !fromAlerts) {
      return { openFollowModal: true }
    } else {
      if (isPlatformBrowser(this.platform)) {
        if (this.isCoverPage(null, actionUrl)) {
          try {
            const subscribeToAlert = await this.apiService.send(API_KEYS.SUBSCRIBE_TO_ALERT);
            const res = await subscribeToAlert({
                alert: actionUrl === '/index.json' ? 'tinyview' : actionUrl.split('/')[1],
                pageUrl: actionUrl,
                isFollow: like || false
              })
            if (meta && meta.showToast && like) {
              this.toastr.success(`You are now following ${meta.series.title === 'All Series' ? 'Tinyview' : meta.series.title}. Thank you!`);
            } 
            return { success: true }
          } catch (error) {
            console.log(error);
            return { success: true }
          }
        }
      }
    }
  }

  public formatDate(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var hours = new Date(date).getHours();
    var minutes = new Date(date).getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;

    let h = hours ? hours : 12 // the hour '0' should be '12'
    let m = minutes < 10 ? '0' + minutes : minutes;
    var strTime = h + ':' + m + ' ' + ampm;
    return days[new Date(date).getDay()] + ", " + monthNames[new Date(date).getMonth()] + " " + new Date(date).getDate() + ", " + new Date(date).getFullYear() + " • " + strTime;
  }

  public getDataDiff(date) {
    if (!date) return '';

    const result = formatDistanceStrict(
      new Date(),
      new Date(date),
      {
        locale: enUS,
      }
    );

    const formattedResult = this.formatCustomDistance(result);
    return formattedResult;
  }

  public formatCustomDistance(distance) {
    let result = distance.replace(/(\d+)\s*(year|hour|month|day|minute|second)s?/g, (match, value, unit) => {
      if (unit.includes('year')) {
        return `${value}y`;
      } else if (unit.includes('month')) {
        const weeks = Math.floor(parseInt(value) * 4.34812); // Average number of weeks in a month
        return `${weeks}w`;
      } else if (unit.includes('day')) {
        return `${value}d`;
      } else if (unit.includes('minute')) {
        return `${value}m`;
      } else if (unit.includes('hour')) {
        return `${value}h`;
      } else if (unit.includes('second')) {
        return `Just now`;
      }
      return match;
    });

    return result;
  }
  public slugify(string) {
    return string
      .toString()
      .trim()
      // .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^-/~._,a-zA-Z0-9]/g, '')
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }

  transform(value) {
    let text = value.toLowerCase();
    if (text.charAt(0) == " ") {
      text = text.trim();
    }
    text = text.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
      .toLowerCase();

    // trim spaces at start and end of string
    text = text.replace(/^\s+|\s+$/gm, '');

    // replace space with dash/hyphen
    text = text.replace(/\s+/g, '-');
    return text;
  }

  public async pageInfoData(actionUrlArray) {
    const pageInfoData = await this.apiService.send(API_KEYS.GET_MULTIPLE_PAGE_INFO) // MultiPageInfo API
    return pageInfoData({
      pages: actionUrlArray
    }).then((result) => {
      return result;
    })
  }

  // public getPanelBorder(comicData): Object {
  //   let res = {};
  //   if (comicData.panelBorder == undefined || comicData.panelBorder == null) {
  //     return res;
  //   }
  //   if (comicData.panelBorder) {
  //     res['border-color'] = comicData.panelBorder['border-color'];
  //     res['border-style'] = 'solid';
  //     res['border-left'] = 'none';
  //     res['border-right'] = 'none';
  //     res['border-bottom'] = !comicData.panelBorder['border-bottom'] ? 'none' : comicData.panelBorder['border-bottom'];
  //     res['border-top'] = !comicData.panelBorder['border-top'] ? 'none' : comicData.panelBorder['border-top'];
  //     res['border-width'] = typeof comicData.panelBorder['border-width'] == 'number' ? comicData.panelBorder['border-width'] + 'px' : comicData.panelBorder['border-width'];
  //   }
  //   return res;
  // }

  public getImageBorder(comicData, isUpload?): Object {
    let res = {};
    if (!this.isComicPage() && !isUpload) { // hide on other pages if not comic page
      return res;
    }
    if (!comicData.image || comicData.border == undefined || comicData.border == null) {
      return res;
    }
    res['border-color'] = comicData.border['border-color'];
    res['border-style'] = 'solid';
    res['border-left'] = !comicData.border['border-left'] ? 'none' : comicData.border['border-left'];
    res['border-right'] = !comicData.border['border-right'] ? 'none' : comicData.border['border-right'];
    res['border-bottom'] = !comicData.border['border-bottom'] ? 'none' : comicData.border['border-bottom'];
    res['border-top'] = !comicData.border['border-top'] ? 'none' : comicData.border['border-top'];
    res['border-width'] = typeof comicData.border['border-width'] == 'number' ? comicData.border['border-width'] + 'px' : comicData.border['border-width'];
    return res;
  }

  // public getSeparatorBorder(comicData): Object {
  //   let res = {};
  //   if (!comicData) {
  //     return res;
  //   }
  //   if (comicData.separatorBottom) {
  //     res['border-bottom'] = '1px solid rgb(222, 223, 224)';
  //   }
  //   if (comicData.separatorTop) {
  //     res['border-top'] = '1px solid rgb(222, 223, 224)';
  //   }
  //   if (comicData.separatorTop || comicData.separatorBottom) {
  //     res['margin'] = '10px 0px';
  //   }
  //   return res;
  // }

  public getComicTitle(): Observable<any> {
    const url = `${COMIC_API}/tinyview/comic-series-directory/directory.json?`;
    let base64Url: string | undefined;
  
    // Generate a unique cache key based on the URL
    if (isPlatformBrowser(this.platform)) {
      base64Url = btoa(url);
    }
  
    // If an Observable is already in progress, return it
    if (!this.getComicTitleCache) {
      const newURL = `${url}${Date.now()}`; // Append a timestamp to avoid stale data
      this.getComicTitleCache = this.http.get<any>(newURL).pipe(
        map(result => {
          // Cache the result for future calls
          if (isPlatformBrowser(this.platform) && base64Url) {
            this.directoryCache[base64Url] = result;
          }
          this.directoryData = result; // Update directory data
          const seriesList = Object.keys(result || {}).map((keys) => `/${keys}`)
          this.cacheService.set(SERIES_LIST_DATA, seriesList)
          return result;
        }),
        shareReplay(1) // Share the result with all subscribers
      );
      setTimeout(() => {
        // Remove the Observable after completion
        this.getComicTitleCache = null;
      }, 3000);
    }
    // Return the cached Observable
    return this.getComicTitleCache;
  }

  public getTitle(action: string | string[]): Observable<string> {
    this.getComicTitle().subscribe(res => {
      for (var key in res) {
        if (!res.hasOwnProperty(key)) continue;
        if (action && action.includes(key)) {
          this.seriesTitle = res[key].title;
        }
      }
    });
    return this.seriesTitle;
  }

  public userData(action: string) {
    var retVal = {};
    if (action && action.includes('index.json')) {
      retVal['image'] = action.split('/').slice(0, 2).join('/') + action.split('/').slice(0, 2).join('/') + '-profile.jpg',
        retVal['action'] = action.split('/').slice(0, 2).join('/') + '/' + 'index.json',
        retVal['name'] = this.getTitle(action);
      return retVal;
    }
  }

  public panelAddToSeriesPage(comicSeries, panel, loggerUniqueID?: number) {
    if (comicSeries) {
      console.log('fetching comic to series page (index file)');
      this.getComicChapters(comicSeries, '').subscribe(async (resp) => {
        console.log('Fetch the series page json file');
        resp.comics.panels.map((i, index) => {
          if (i.title == latestComics) {
            panel['template'] = "toc";
            // panel['panelBorder'] = {
            //   "border-color": "#dedfe0",
            //   "border-width": 1,
            //   "border-top": true
            // };
            delete panel['description'];
            resp.comics.panels.splice(index + 1, 0, panel);
          }
        });
        await resp.comics.panels.map((resp) => {
          delete resp.likeCount;
          delete resp.viewCount;
        });
        this.storageService.uploadIndex(comicSeries, resp.comics);
        console.log('Uploaded comic to series page (index file)');
        this.loggerService.logEvent('Creator UI - Add Comic', 'Panel is added successfully in series index.json.', { comicSeries, comics: resp.comics, panel }, loggerUniqueID);
      }, err => {
        console.log('Getting error while updating series page (index file)', err);
        this.loggerService.logEvent('Creator UI - Add Comic', 'ERROR while adding comic on series index.json.', { comicSeries, panel, error: err }, loggerUniqueID);
      });
    }
  }

  public async panelAddToHomePage(comicSeries, path, panel, panels, loggerUniqueID?: number) {
    // console.log('adding comic to home page');
    // this.getComicChapters('', '').subscribe(async (resp) => {
      // resp.comics.panels.map(async (i, index) => {
        // if (i.title == latestComics) {
          panel.user = this.userData(path + '/index.json');
          panel.template = "story";
          panel['series'] = comicSeries.split('/').slice(0, 2)[1];
          // panel['panelBorder'] = {
          //   "border-color": "#dedfe0",
          //   "border-width": 1,
          //   "border-top": true
          // };
          delete panel['description'];
          // resp.comics.panels.splice(index + 1, 0, panel);
          // await resp.comics.panels.map((res) => {
          //   delete res.likeCount;
          //   delete res.viewCount;
          // });
          // this.storageService.uploadIndex('/', resp.comics);
          const storyFromComic = await this.apiService.send(API_KEYS.STORY_FROM_COMIC_WRITER);
          let storyData = [];
          storyData.push(panel);
          this.localStorageService.setItem('payload', JSON.stringify({
            storyData,
            "notificationData": {
              "payload": {
                "topic": panel.series,
                "deeplink_path": comicSeries + "/index.json",
                "imageUrl": panel.series + '/' + panel.series + '-profile.jpg'
              },
              "scheduledTimeInSeconds": new Date(panel.datetime).getTime() / 1000
            }
          }))
          await storyFromComic({
            storyData,
            "notificationData": {
              "payload": {
                "topic": panel.series,
                "deeplink_path": comicSeries + "/index.json",
                "imageUrl": panel.series + '/' + panel.series + '-profile.jpg'
              },
              "scheduledTimeInSeconds": new Date(panel.datetime).getTime() / 1000
            }
          }).then(() => {
            console.log('Story from comic writer called');
            this.storyWriterComics$.next(true);
          }).catch((err) => {
            this.storyWriterComics$.next(true);
            console.log('BE function successfully executed');
            this.loggerService.logEvent('Debugging - Creator UI', 'storyWriterComics API error', { error: err }, loggerUniqueID);
          }).catch((err) => {
            console.log(err);
            this.storyWriterComics$.next(true);
          });
      //   }
      // });
    // }, err => {
    // });
  }

  public isPlatformAvailable(imageData): boolean {
    let platformFlag = true;
    if (imageData && (imageData.platforms !== undefined)) {
      platformFlag = false;
      for (const p of imageData.platforms) {
        if (Object.keys(p)[0] == this.currentDevice) {
          platformFlag = true;
          break;
        }
      }
    }
    return platformFlag;
  }

  public getComicAction(comicData) {
    let action;
    if (comicData.action == '/index.json') {
      action = '/'
    } else {
      action = (comicData && comicData.action) ? comicData.action.split('/index.json')[0] : comicData ? comicData.action : null;
    }
    return action;
  }

  public getNavigateUrl(comicData): string {
    if (comicData && !comicData.action) {
      return null;
    }
    const action = this.resolvePath(this.getCurrentUrl(), comicData ? comicData.action : null);
    return action ? this.resolveJsonInAction(action) : null;
  }

  public toTitleFormat(title: String) {
    return !title ? 'Tinyview Homepage' :
      title.split('/')
        .pop()
        .split('-')
        .map((s) => s.charAt(0).toUpperCase() + s.substr(1))
        .join(' ');
  }

  onShare() {
    if (this.currentDevice == 'androidBrowser' || this.currentDevice == 'iosBrowser') {
      this.share();
    } else {
      this.showShare = !this.showShare;
    }
  }
  async share() {
    let newVariable: any;
    if (isPlatformBrowser(this.platform) && window && window.navigator) {
      newVariable = window.navigator;
      if (newVariable && newVariable.share) {
        try {
          await newVariable.share(this.getShareData());
        } catch (err) {
        }
      }
    }
  }

  public async navigateByActions(comicData) {
    if (this.isTinyView(comicData) && comicData.action) {
      if (comicData.action == 'share') {
        this.onShare();
      }
      if (['download-app-android', 'download-app-ios'].includes(comicData.action)) {
        this.openInStore();
      }
      if (comicData.action == 'restore-purchases') {
      }
    }
  }

  public isTinyView(comicData): boolean {
    return (comicData && (comicData.actionType === 'tinyview'));
  }

  async checkPremiumComic (url, showTo, influencePrice?) {
    await this.getUnlockedComicsURLs();
    const isSharedByPremiumUser = this.cacheService.get('isSharedByPremiumUser');
    let userProdIDs = this.localStorageService.getItem('productID');
    let storedURLs = this.sessionStorageService.getItem('unlockedURLs');
    const isPremiumComic = (['Subscriber Only', 'subscribers-only', 'premium'].includes(showTo)) || (influencePrice && influencePrice > 0);
    let hasAccess: boolean;
    if (isPremiumComic) {
      if (storedURLs.length > 0 && storedURLs.includes(`${url}/index.json`)) {
        hasAccess = true;
      } else if (userProdIDs.length > 0 || isSharedByPremiumUser) {
        hasAccess = true;
      }
    }
    return {
      isPremiumComic,
      hasAccess
    }
  }

  public async viewsForExternalLink(comicAction, isRead?, storyID?: string, seriesName?: string) { // storyID for Comments page
    if (comicAction && comicAction.startsWith('//')) {
      comicAction = comicAction.replace('//', '/');
    }
    if (!storyID) {
      const getStory = await this.apiService.send(API_KEYS.GET_STORY_DETAILS);
      const reqData: any = { action: comicAction, series: seriesName || '' };
      const pageStory = await getStory(reqData);
      storyID = pageStory.data.data.storyID;
    }
    const registerViews = await this.apiService.send(API_KEYS.RECORD_PAGEVIEW);
    registerViews({
      pageUrl: comicAction,
      storyID,
      isRead: isRead || 0 // pass 0 to only add page views || pass 1 for add page view plus mark the comic as READ
    }).then((result) => {
    });
  }

  public async checkComicReadAccess(comicUrl: string, fromBanner = false) {
    if (fromBanner && this.accessInfo) return this.accessInfo;
    const hasComicReadAccess = await this.apiService.send(API_KEYS.HAS_COMIC_READ_ACCESS);
    try {
      const res = await hasComicReadAccess({
        "pageUrl": `${comicUrl}/index.json`
      });
      this.accessInfo = res.data;
      return res.data
    } catch (err) {
      console.log("Error while calling hasComicReadAccess API ", err)
    }
  }

  public combineDateAndTime(date, time) {
    if (date && time) {
      var time = time.split(':');
      const mins = time[1];
      const hours = time[0];
      const secs = '00';
      const timeString = hours + ":" + mins + ":" + secs;
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const dateString = "" + year + "-" + month + "-" + day;
      const datec = dateString + "T" + timeString;
      return new Date(datec);
    }
  };

  public getFormattedSeriesNames(series): Object {
    let obj = {}
    for (let data in series) {
      obj[data] = series[data].title;
    }
    return obj;
  }

  public getFirstLetters(str) {
    if (str) {
      const words = str.split(" ");
      const firstLetters = words
        .map(word => word[0])
        .join('');
      if (firstLetters.length > 2) {
        return firstLetters.slice(0, 2).toUpperCase();
      } else {
        return firstLetters.toUpperCase();
      }
    }
  }

  public mapUserBadge(badgeName: string, size = 25): string {
    if (!badgeName) return '';

    const badgePath = `${IMAGE_END_POINT}/assets/images/badge-`;
    switch (badgeName.toLowerCase()) {
      case 'tinyview':
        return `${badgePath}tinyview-${size}.png`;
      case 'creator':
        return `${badgePath}verified-${size}.png`;
      case 'subscriber-pizza':
      case 'com.tinyview.subscription.pizza':
        return `${badgePath}pizza-${size}.png`;
      case 'subscriber-artsupplies':
      case 'com.tinyview.subscription.artsupplies':
        return `${badgePath}art-${size}.png`;
      case 'subscriber-coffee':
      case 'com.tinyview.subscription.coffee':
        return `${badgePath}coffee-${size}.png`;
      case 'subscriber-bagel':
      case 'com.tinyview.subscription.bagel':
        return `${badgePath}bagel-${size}.png`;
      case 'subscriber-cookie':
      case 'com.tinyview.subscription':
        return `${badgePath}cookie-${size}.png`;
      case 'virtual-gift':
        return `${badgePath}gift-${size}.png`;
    }
  }

  public getHighestSubscriptionFromArray(productIDs) {
    subscriptionLevelsFull.reverse();
    let highestSub = subscriptionLevelsFull.find(sub => !!productIDs.find(pId => pId.indexOf(sub) > -1));
    subscriptionLevelsFull.reverse();
    return highestSub;
  }

  public getHighestSubscriptionFromObject(productIDsObj) {
    let userProducts = JSON.parse(JSON.stringify(productIDsObj));

    if (userProducts.find((sub) => sub.id === 'com.tinyview.subscription')) {
      userProducts = userProducts.map(sub => {
        if (sub.id === 'com.tinyview.subscription') {
          sub.id = 'com.tinyview.subscription.cookie';
        }
        return sub;
      });
    }
    subscriptionLevels.reverse();
    let highestSub = subscriptionLevels.find(sub => !!userProducts.find(pId => pId.id.indexOf(sub) > -1));
    subscriptionLevels.reverse();
    return highestSub;
  }

  public async unlockComic(url: string, pointsToBeCharged?: number) {
    const redeemInfluencePoint = await this.apiService.send(API_KEYS.REDEEM_INFLUENCE_POINT);
    try {
      const res = await redeemInfluencePoint({
        point: pointsToBeCharged || 1,
        url: url
      });
      console.log("Res redeem infulence point ", res.data)
    } catch (err) {
      console.log("Error while redeem infulence point ", err)
    }

    return { success: true }
  }

  public async getUnlockedComicsURLs(): Promise<any> {  
    // If a promise already exists, return it to prevent duplicate API calls
    if (!this.getUnlockedComicsURLsPromise) {
      this.getUnlockedComicsURLsPromise = new Promise(async (resolve, reject) => {
        try {
          const getUnlockComicURLs = await this.apiService.send(API_KEYS.GET_UNLOCK_COMIC_URLS);
          let response = [];
          try {
            const res = await getUnlockComicURLs({});
            response = res.data.data.urls;
          } catch (error) {
            console.error('Error while redeeming influence points:', error);
          }
  
          // Cache the result in session storage
          this.sessionStorageService.setItem('unlockedURLs', JSON.stringify(response || []));
          resolve(response); // Resolve the promise with the response
        } catch (error) {
          console.error('Error in getUnlockedComicsURLs:', error);
          reject(error); // Reject the promise if an error occurs
        }
      });
      // Reset the promise after completion
      setTimeout(() => {
        this.getUnlockedComicsURLsPromise = null;
      }, 1000);
    }
  
    // Return the shared promise
    return this.getUnlockedComicsURLsPromise;
  }

  public getShareBranchLinkData(actionURL?: string): any {
    const COMIC_API = `${environment.API_END_POINT}`;
    const checkURLs = ['/tinyview/comic-series-directory', '/']
    const referredByCookie = firebase.auth().currentUser.uid;
    const currentURL = this.getCurrentUrl();
    let currentUrl = actionURL || `${checkURLs.includes(currentURL) ? '' : currentURL}/index.json`;
    const deeplinkUrl = ((currentUrl.indexOf('/index.json') > -1) || currentUrl.startsWith('/story')) ? currentUrl : `${currentUrl}/index.json`;

    // TODO(shashank): make it through params
    let metaTagsData = this.sessionStorageService.getItem('metaTagsInfo');

    return {
      referred_by: referredByCookie,
      deeplink_path: `${COMIC_API}${deeplinkUrl}`,
      deeplink_url: deeplinkUrl,
      fallback_url: DOMAIN_NAME,
      $desktop_url: DOMAIN_NAME,
      $og_title: metaTagsData ? metaTagsData['og:title']: "Tinyview",
      $og_description: metaTagsData ? metaTagsData['og:description']: "Bite-sized comics for your coffee breaks! Whether you're a kid or a grown-up, a science fanatic or a political junkie, there's something for everyone on Tinyview. Read comics for FREE. Subscribe to support creators, unlock bonus panels, and participate in the discussion. Cancel at any time.",
      $og_image_url: metaTagsData ? metaTagsData['og:image'] : "https://storage.googleapis.com/tinyview-dev.appspot.com/tinyview/tinyview-fb-cover.jpg"
    }
  }

  public getInviteBranchLinkData(): any {
    const COMIC_API = `${environment.API_END_POINT}`;
    const referredByUID = firebase.auth().currentUser.uid;
    const loggedInUserDetails = this.userDetails && this.userDetails.data;
    const currentURL = this.getCurrentUrl();
    let currentUrl = `${currentURL === '/' ? '' : currentURL}/index.json`;

    return {
      shared_by: referredByUID, //Sender UID
      referred_by: referredByUID, //Sender UID
      senderUID: referredByUID,
      senderName: loggedInUserDetails && loggedInUserDetails.displayName,
      senderNumber: loggedInUserDetails && loggedInUserDetails.phoneNumber,
      senderProfilePic: loggedInUserDetails && loggedInUserDetails.photoURL, // userDetails.photoURL
      actionType: "INVITE_FRIEND",
      deeplink_path: `${COMIC_API}${currentUrl}`,
      fallback_url: DOMAIN_NAME,
      $desktop_url: DOMAIN_NAME
    };
  }

  async isUserAComicCreator(): Promise<boolean> {
    if ((this.userDetails && this.userDetails.val && this.userDetails.val.data && this.userDetails.val.data.badges.length) || (this.userDetails && this.userDetails && this.userDetails.data && this.userDetails.data.badges.length)) {
      const badges = this.userDetails.data.badges || (this.userDetails.val && this.userDetails.val.data.badges);
      return !!badges.includes('creator');
    }
    return false;
  }

  async hasAnySubscriptionPurchase(): Promise<boolean> {
    if ((this.userDetails && this.userDetails.val && this.userDetails.val.data && this.userDetails.val.data.subscriptions.length) || (this.userDetails && this.userDetails && this.userDetails.data && this.userDetails.data.subscriptions.length)) {
      return true;
    }
    return false;
  }

  public async getInfluenceData(actionURL?: string, storyID?: string, isWebsite?: boolean, isSubscriber?: any) {
    let tags = 'tinyview';
    const utm_campaign = await this.isUserAComicCreator() ? REFERRAL_CONSTS.CREATOR : await this.hasAnySubscriptionPurchase() ? REFERRAL_CONSTS.SUBSCRIBER : REFERRAL_CONSTS.USER
    const utmData = {
      campaign: utm_campaign || 'website',
      feature: REFERRAL_CONSTS.REFERRAL || 'open-in-app',
      channel: firebase.auth().currentUser.uid || 'tinyview'
    };

    const linkData = {
      ...utmData,
      tags: [tags],
      data: this.getShareBranchLinkData(actionURL)
    };

    linkData.data = {
      ...linkData.data,
      actionType: isWebsite ? 'story' : '',
      storyID: isWebsite ? storyID : '',
      shared_by: linkData.data.referred_by,
      utm_data: utmData
    }
    if (isSubscriber) {
      linkData.data['isSharedByPremiumUser'] = !!isSubscriber;
    }
    // console.log('branch link data', linkData);
    return linkData;
  }

  public getInviteData() {
    let tags = 'tinyview';
    const linkData = {
      campaign: 'website',
      feature: 'open-in-app',
      channel: 'tinyview',
      tags: [tags],
      data: this.getInviteBranchLinkData()
    };
    linkData.data = {
      ...linkData.data,
      actionType: linkData.data.actionType,
      shared_by: linkData.data.referred_by,
      deeplink_url: linkData.data.deeplink_path,

    }

    return linkData;
  }

  public async shareConditions(shareText: string, action: any, isDesktop, isAndroid?, isIOS?) {
    const url = encodeURI(shareText);
    switch (action) {
      case 'openSMS':
        window.location.href = isAndroid ? `sms:?body=${shareText}` : `sms:&body=${shareText}`;
        break;
      case 'openWhatsApp':
        const shareLink = isDesktop ? `https://web.whatsapp.com/send?text=${shareText}` : `whatsapp://send?text=${shareText}`;
        window.open(shareLink, "_blank");
        break;
      case 'copyLink':
        await navigator.clipboard.writeText(shareText); // working on desktop browser
        this._clipboardService.copy(shareText); // working on all browser except ios
        // this.fallbackCopy(shareText); // working on ios browser
        const customClass = isDesktop ? {} : { positionClass: 'toast-bottom-center' };
        this.toastr.success('Link copied!', '', customClass);
        break;
      case 'openInstagram':
        window.location.href = `instagram://sharesheet?text=${shareText}`;
        break;
      case 'openFacebook':
        const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&utmSource=facebook&utmContent=referral&utmTerm=topbar&referrer=facebook`;
        this.popUpUrl(fbShareUrl);
        break;
      case 'openTwitter':
        const twitterShareUrl = `https://www.twitter.com/intent/tweet?url=${url}&via=TinyviewComics&utmSource=twitter&utmContent=referral&utmTerm=topbar&referrer=twitter`;
        this.popUpUrl(twitterShareUrl);
        break;
      case 'openSystemDefaultShare':
        let wNavigator;
        wNavigator = window.navigator;
        if (wNavigator && wNavigator.share) {
          try {
            await wNavigator.share({
              title: 'Tinyview',
              url: shareText,
            });
          } catch (err) {
            // window.location.reload();
            console.log('wNavigator.share error', err);
          }
        }
        break;
    }
  }

  public async manualShare(actionURL, storyID, share, isDesktop, isAndroid?) {
    const data = await this.getInfluenceData(actionURL, storyID);
    // console.log('storyID', storyID);
    // console.log('actionURL', actionURL);
    // console.log('data', data);
    window['branch'].link(data, (err, link) => {
      this.shareConditions(link, share.action, isDesktop, isAndroid);
    });
  }

  public inviteFriends(share, loggedInUserName, isDesktop, isAndroid?, receiverPhoneNumber?) {
    const data = this.getInviteData();
    if (receiverPhoneNumber) {
      data.data.actionType = 'FRIEND_REQUEST';
      data.data.receiverContact = receiverPhoneNumber;
    }
    console.log('data', data);
    window['branch'].link(data, (err, link) => {
      if (['openSystemDefaultShare', 'openFacebook'].includes(share)) {
        this.shareConditions(link, share, isDesktop, isAndroid);
      } else {
        this.shareConditions(`Hey there! Your friend ${loggedInUserName} thinks you'd love Tinyview Comics. Let's connect and dive into the fun together! ${link}`, share, isDesktop, isAndroid);
      }
    });
  }

  public setMetaData(data) {
    // if (!data || !data.image) return;
    // if (data.image.indexOf('.com') === -1) {
    //   data.image = `${environment.API_END_POINT}/${data.image}`
    // }

    // const strippedDescription = data.description && data.description.replace(/(<([^>]+)>)/ig, '');

    // const metaData = [{
    //   property: 'twitter:description',
    //   content: strippedDescription
    // },
    // {
    //   property: 'twitter:image',
    //   content: `${data.image}`
    // },
    // {
    //   property: 'twitter:title',
    //   content: `${data.title}`
    // },
    // {
    //   property: 'og:title',
    //   content: `${data.title}`
    // },
    // {
    //   property: 'og:description',
    //   content: strippedDescription
    // },
    // {
    //   property: 'og:image',
    //   content: `${data.image}`
    // },
    // {
    //   property: 'og:url',
    //   content: `https://tinyview.com${this.comicAction || ''}`
    // },
    // ];
    // for (const metaProperty of metaData) {
    //   this.meta.updateTag({
    //     property: metaProperty.property,
    //     content: metaProperty.content
    //   });
    // }
  }

  public popUpUrl(url: string): void {
    const w = 600;
    const h = 500;
    const title = "Share Tinyview";
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )
    if (window.focus) newWindow.focus();
  }

  public getCTAList() {
    const userDetails = this.userDetails && this.userDetails.data;
    const ctaList = [];
    if (util.isAnonymousUser()) {
      ctaList.push('signin');
    } else if (!this.userDetails.data.userName || !this.userDetails.data.gender) {
      ctaList.push('incompleteProfile')
    } else if (!userDetails || (!userDetails.subscriptions || !userDetails.subscriptions.length) && (!userDetails.badges || !userDetails.badges.length)) {
      ctaList.push('subscribe');
    }
    return ctaList;
  }

  public getShareItems(isDesktop, isAndroid, isWindows, isComicPage?: boolean) {
    let updatedArray = shareConfig.filter(config => {
      // For Desktop
      if (['openInstagram', 'openSystemDefaultShare'].includes(config.action) && isDesktop) {
        return !isDesktop;
      }
      // For Android
      if (['openInstagram'].includes(config.action) && isAndroid) {
        return !isAndroid;
      }
      // For Windows
      if (['openSMS'].includes(config.action) && isWindows) {
        return !isWindows;
      }

      // For Comic page we need to hide More option
      if (['openSystemDefaultShare'].includes(config.action) && isComicPage) {
        return !isComicPage;
      }
      return true;
    });

    updatedArray = updatedArray.map(arr => {
      if (isAndroid && arr.androidImage) {
        arr.image = arr.androidImage;
      }
      return arr;
    })

    return updatedArray;
  }

  public async addInfluencePoint(data) {
    const addInfluencePointAPI = await this.apiService.send(API_KEYS.ADD_INFLUENCE_POINT);
    const res = await addInfluencePointAPI(data);

    return res.data;
  }

  public provideInfluencePoint(params) {
    const refID = this.localStorageService.getItem('referred_by');
    const inviteFlow = this.localStorageService.getItem('invite_friends_flow_completed');

    let winPathName = '/';

    // SSR check
    if (typeof window !== "undefined") {
      winPathName = window.location.pathname;
    }
    const winPath = winPathName === '/' ? '' : winPathName;

    const path = (winPath === '/')
      ? ''
      : winPath.endsWith("/index.json")
        ? winPath
        : winPath + "/index.json";
    if (((Object.keys(params) || []).indexOf('_branch_match_id') > -1) && refID && !inviteFlow) {
      this.addInfluencePoint({
        action: "REFERRER",
        referrerID: refID,
        url: `${COMIC_API + path}`
      }).then()
        .catch(err => console.log('Unable to provide influence point', err))
    }
  }

  public async setReferrer(data) {
    const setReferrerAPI = await this.apiService.send(API_KEYS.SET_REFERRER);
    const res = await setReferrerAPI(data);

    return res.data;
  }

  public async provideReferralPoint(params) {
    const utmData = this.localStorageService.getItem('utm_data');
    const isUserSubscribed = await this.hasAnySubscriptionPurchase();
    if (((Object.keys(params) || []).indexOf('_branch_match_id') > -1) && !isUserSubscribed && utmData && utmData.feature && utmData.campaign === REFERRAL_CONSTS.CREATOR && utmData.channel) {
      this.setReferrer({
        utm_medium: utmData.feature,
        utm_campaign: utmData.campaign,
        utm_source: utmData.channel
      }).then(res => this.localStorageService.removeItem('utm_data'))
        .catch(err => console.log('Unable to provide referral point', err))
    }
  }

  public fallbackCopy(text) {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  // new mockup functions
  public getHeaderPanel() {
    let url = ((this.getCurrentUrl() != '/') && (this.getCurrentUrl() != '/tinyview/comic-series-directory') && (this.getCurrentUrl() != subscribeURL) && (this.getCurrentUrl() != '/followings-comics')) ? `${COMIC_API}${this.formatSeriesName(this.getCurrentUrl()).replace('/footer.json', '')}/header.json?${Date.now()}` : `${COMIC_API}/tinyview/header.json?${Date.now()}`
    return this.http.get<any>(url)
      .pipe(
        map(result => {
          return result;
        })
      );
  }

  public getNewsFeedChapters(comicSlug): Observable<any> {
    comicSlug = comicSlug === '/followings-comics' ? '/' : comicSlug;
    let url = `${COMIC_API}`;
    url += comicSlug != '/' ? `${comicSlug}/newsfeed.json?` : `/newsfeed.json`
    url += comicSlug != '/' ? `${Date.now()}` : ``;
    return this.http.get<any>(url)
      .pipe(
        map(result => {
          if (comicSlug === '') {
            this.homePagePreview = {
              image: `${environment.API_END_POINT}/${result.comics.ogImage}`,
              description: result.comics.description,
              title: result.comics.title,
              url: 'https://tinyview.com'
            };
          }
          return result;
        })
      );
  }

  public extractDomain(url) {
    var domain;
    //find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    }
    else {
      domain = url.split('/')[0];
    }
    //find & remove www
    if (domain.indexOf("www.") > -1) {
      domain = domain.split('www.')[1];
    }
    domain = domain.split(':')[0]; //find & remove port number
    domain = domain.split('?')[0]; //find & remove url params
    return domain;
  }

  public resolvePath = (pageURL, url) => {
    let relative = url + '';
    if (relative.includes('http')) {
      return relative;
    }
    const base = pageURL || pageURL == "" || pageURL == "/" ? pageURL : null;
    if (relative && relative.startsWith('/')) {
      // return settings.apiBaseURL + relative
      return relative;
    }
    const stack = base || base == "" ? base.split('/') : null;
    const parts = relative && relative.split('/');
    // stack.pop();
    for (const index in parts) {
      if (parts[index] == ".") {
        continue;
      }
      if (parts[index] == "..") {
        stack.pop();
      } else if (stack) {
        stack.push(parts[index]);
      }
    }
    const finalUrl = stack ? stack.join('/') : '';
    return finalUrl;
  }

  public getHeaderChapters(comicSlug: string): Observable<any> {
    let url = `${COMIC_API}`;
    url += comicSlug + '?' + `${Date.now()}`;
    if (isPlatformBrowser(this.platform)) {
      var base64Url = btoa(url);
    }
    if (this.directoryCache[base64Url]) {
      if (isPlatformBrowser(this.platform)) {
        return of(this.directoryCache[base64Url])
      }
    } else {
      return this.http.get<any>(url)
        .pipe(
          map(result => {
            this.directoryCache[base64Url] = result;
            return result;
          })
        );
    }
  }

  // public getComicTitle() {
  //   let url = `${COMIC_API}/tinyview/comic-series-directory/directory.json?`;
  //   if (isPlatformBrowser(this.platform)) {
  //     var base64Url = btoa(url);
  //   }
  //   if (this.directoryCache[base64Url]) {
  //     if (isPlatformBrowser(this.platform)) {
  //       return of(this.directoryCache[base64Url])
  //     }
  //   } else {
  //     let newURL = `${url}${Date.now()}`;
  //     return this.http.get<any>(newURL)
  //       .pipe(
  //         map(result => {
  //           this.directoryCache[base64Url] = result;
  //           this.directoryData = result;
  //           return result;
  //         })
  //       );
  //   }
  // }

  public getOperatingSystem(): string {
    let currentDevice;
    if (isPlatformBrowser(this.platform) && navigator) {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        currentDevice = BROWSER.ANDROID;
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        currentDevice = BROWSER.IOS;
        // } else if (/windows/i.test(userAgent)) {
        //   currentDevice = BROWSER.WINDOWS;
      } else {
        currentDevice = BROWSER.DESKTOP
      }
    } else {
      currentDevice = BROWSER.DESKTOP;
    }
    return currentDevice;
  }

  public isWindows(): boolean {
    if (isPlatformBrowser(this.platform) && navigator) {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/windows/i.test(userAgent)) {
        return true;
      }
    }
    return false;
  }

  public checkMinFriendsCondition(user) {
    const minFriendForComicAccess = user && user.data && user.data.minFriendFreeComicAccess;
    const userFriendCount = user && user.data && user.data.friendCount;
    switch (minFriendForComicAccess) {
      case -1:
        return false; // Handle with condition further requirements.
      case 0:
        return false; // Handle with condition further requirements.
      default:
        if (minFriendForComicAccess <= userFriendCount) {
          return true
        } else {
          return false
        }
    }
  }

  // For Feature: monthlyfree comic
  async checkUserComicAccess() {
    if (!isAnonymousUser()) {
      return {
        hasAllComicAccess: true,
        isSubscriber: false
      }
    } else {
      const user = this.userDetails || await this.getUserDetails();
      const monthlyFreeLimit = user && user.data && user.data.monthlyFreeComic;
      const isSubscriber = !!(user && user.data && user.data.subscriptions[0] && user.data.subscriptions[0].productID);
      switch (monthlyFreeLimit) {
        case -1:
          return {
            hasAllComicAccess: true,
            isSubscriber
          };
          break;
        default:
          return {
            hasAllComicAccess: this.checkMinFriendsCondition(user),
            monthlyFreeComic: monthlyFreeLimit,
            isSubscriber
          };
      }
    }
  }

  // Comic Access to a non-subscriber

  async checkComicAccessForNonSubscriber(comicData) {
    const isSharedByPremiumUser = this.cacheService.get('isSharedByPremiumUser');
    const isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
    let storedURLs = this.sessionStorageService.getItem('unlockedURLs') || [];
    if (isSubscriber || isSharedByPremiumUser || (storedURLs.length > 0 && storedURLs.includes(`${this.getCurrentUrl()}/index.json`))) {
      return {
        hasAccess: true,
        priority: 2
      }
    }
    const directoryData = await this.getComicTitle().toPromise();
    const currentSeriesData = await this.getPageInfo();
    const currentSeriesName = this.getCurrentUrl().split('/')[1];
    const seriesFreeEpisodesLimit = directoryData[`${currentSeriesName}`] && Number(directoryData[`${currentSeriesName}`].freeEpisodes);
    const seriesFreeHoursLimit = directoryData[`${currentSeriesName}`] && directoryData[`${currentSeriesName}`].freeHours;
    const userViewCount = Number(currentSeriesData.data.seriesReadCount);
    if (!seriesFreeEpisodesLimit || !seriesFreeHoursLimit || seriesFreeEpisodesLimit === -1 || seriesFreeHoursLimit === -1) {
      return {
        hasAccess: true,
        priority: 2
      }
    }
    if (userViewCount < seriesFreeEpisodesLimit) {
      return {
        hasAccess: true,
        priority: 0,
        leftCount: seriesFreeEpisodesLimit - userViewCount
      }
    } else if (isComicTimeRecent(comicData.datetime, seriesFreeHoursLimit) || userViewCount == seriesFreeEpisodesLimit) {
      return {
        hasAccess: true,
        priority: 2
      }
    } else {
      return {
        hasAccess: false,
        priority: 1,
        freeHours: seriesFreeHoursLimit
      }
    }
  }

  isVideoWebsiteDomain(domains: string): string {
    const isVideoWebsiteDomain = youtubeDomains.includes(domains);
    return isVideoWebsiteDomain ? 'Watch' : 'Open Link';
  }

  public async getPageInfo(): Promise<any> {
    // If a promise already exists, return it to prevent duplicate API calls
    if (!this.getPageInfoPromise) {
      this.getPageInfoPromise = new Promise(async (resolve, reject) => {
        try {
          const currentUrl = this.getCurrentUrl();
          const getPageInfo = await this.apiService.send(API_KEYS.GET_PAGEVIEW_INFO); // API call
          const result = await getPageInfo({
            pageUrl: ['', '/'].includes(currentUrl) ? `/index.json` : `${currentUrl}/index.json`
          });
          if ( this.isComicPage) {
            const isReadAlready = result.data.isRead;
            if (!isReadAlready) result.data.seriesReadCount += 1;
          }
          resolve(result); // Resolve the promise with the response
        } catch (error) {
          console.error('Error in getPageInfo:', error);
          reject(error); // Reject the promise if an error occurs
        }
      });
      setTimeout(() => {
        this.getPageInfoPromise = null;
      }, 1000); // Adjust delay as needed
    }
  
    // Return the shared promise
    return this.getPageInfoPromise;
  }

  userHasAnyChannel(userData) {
    let hasAnyChannel = false;
    const values = userData.data.notificationSettings;
    for (const [key, value] of Object.entries<any>(values)) {
      if (key === 'id') continue;
      if ('enabled' in value) {
        if (key === 'push' && userData.data.hasApp && value.enabled) hasAnyChannel = true;
        if (value.enabled && key !== 'push') hasAnyChannel = true;
      }
    };
    return hasAnyChannel;
  }

  public clearUserData() {
    this.isFollowedComic = false;
    this.userLikesArray = [];
    this.cacheComic = {}
  }

  public hasBonusTemplate(chapterList) {
    return chapterList.some(chapter => chapter.template === templateTypes.bonus);
  }

  public hasFollowBtn(chapterList) {
    return chapterList.some(chapter => chapter.action === 'follow');
  }

  public async updateUserSettings( { showAllComics }) {
    const updateUserSettings = await this.apiService.send(API_KEYS.UPDATE_USER_SETTINGS); // MultiPageInfo API
    updateUserSettings({
      "showAllComics": showAllComics
    }).then(() => {
      if (isPlatformBrowser(this.platform)) {
        this.localStorageService.setItem('showAllComics', JSON.stringify(showAllComics));
      }
    })
  }

  public getCDNImgUrl(image: string) {
    try {
      if (image && (image.includes('http') || image.includes('https://storage.googleapis.com/'))) {
        const CDN_BUC = environment.OLD_API_END_POINT;
        const ASSETS_BUC = environment.OLD_IMAGE_END_POINT;
        if (image.includes(CDN_BUC)) {
          image = image.replace(CDN_BUC, environment.API_END_POINT);
        } else if (image.includes(ASSETS_BUC)) {
          image = image.replace(ASSETS_BUC, environment.IMAGE_END_POINT);
        }
        return image;
      } else {
        return image;
      }
    } catch (error) {
      console.log('Error while getting getCDNImgUrl: ', error);
      return image;
    }
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NewComicService } from 'src/app/new-comic.service';
import { ComicService } from 'src/app/services/comic.service';
import { MenuComponent } from 'src/app/menu/menu.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isAnonymousUser } from 'src/app/utilities/common.util';
import { NotificationService } from 'src/app/services/notification.service';
import { getImage } from 'src/app/constants/images.constants';
import { signInPhoneURL, subscribeURL, TINYVIEW_SHOP } from 'src/app/constants/common.constants';
import { PanelNavService } from 'src/app/services/panel-nav.service';

@Component({
  selector: 'app-bottom-nav-mobile',
  templateUrl: './bottom-nav-mobile.component.html',
  styleUrls: ['./bottom-nav-mobile.component.scss', './../../pages/comic-wrapper/comic-wrapper.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'

      })),
      state('out', style({
        transform: 'translate3d(100%,0,0)',
        'display': 'none'
      })),
      transition('in => out', animate('1ms ease-in-out')),
      transition('out => in', animate('1ms ease-in-out'))
    ]),
  ]
})
export class BottomNavMobileComponent implements OnInit {
  public currentURL: string;
  public activeIcon: string;

  /// old Slider
  @ViewChild(MenuComponent, { static: false }) chviewChild: MenuComponent;
  public menuState: string = 'out';
  public name: string;
  public clickedOutside: boolean = true;
  userImage: any;
  userName: any;
  userBadges = [];
  userPhoneNumber: any;
  isSubscribed: boolean;
  manageSubscriptionObj: any;
  public previousItem: string;

  public disableBtn = {
    prevComic: true,
    nextComic: true,
  };

  public btnNavigation = {
    prevComic: null,
    nextComic: null
  };

  iconUrls: { [key: string]: string } = {
    directory: this.getImagePath('ICONS8_SIGNPOST_100'),
    shop: this.getImagePath('ICONS8_SHOPPING_BAG_100_2'),
    tickbox: this.getImagePath('TICKBOX'), // removed and placed above the bottom bar
    home: this.getImagePath('ICONS8_HOME_100'),
    user: this.getImagePath('ICONS8_USER_MENU_MALE_100'),
    notification: this.getImagePath('ICONS8_ALARM_100'),
    share: this.getImagePath('ICONS8_UPLOAD_100'),
    back: this.getImagePath('ICONS8_BACK_100'),
    'previous-comic': this.getImagePath('ICONS8_REWIND_100'),
    'next-comic': this.getImagePath('ICONS8_PLAY_100'),
    'series-home': this.getImagePath('ICONS8_LIST'),
  };
  iconUrlsDark: { [key: string]: string } = {
    directory: this.getImagePath('ICONS8_SIGNPOST_100_2'),
    shop: this.getImagePath('ICONS8_SHOPPING_BAG_100'),
    tickbox: this.getImagePath('TICKBOX_DARK'), // removed and placed above the bottom bar
    home: this.getImagePath('ICONS8_HOME_100_2'),
    user: this.getImagePath('USER_DARK'),
    notification: this.getImagePath('ICONS8_ALARM_100_2'),
    'previous-comic': this.getImagePath('ICONS8_REWIND_100_626262'),
    'next-comic': this.getImagePath('ICONS8_PLAY_100_626262'),
    'series-home': this.getImagePath('ICONS8_LIST_100_626262'),
  };
  isMobile: boolean;
  inviteFriendsRef: any;
  isAnonymous: any;
  isIncompleteProfile: boolean;
  unreadCount: string;
  isSeriesPage: boolean;
  isComicPage: boolean;
  isStoryPage: boolean;
  isUserFollowingCurrentSeries: boolean;
  userDetailsLoading: boolean;
  continueReadingData: any;
  followBtnLoading: boolean;
  seriesEpisodes: any;
  currentComicAction: any;
  seriesHome: any;
  isExternalLink: boolean;
  externalBtnCta: string;
  isHomePage: boolean;
  episodeBtnCta: string;
  subDetails: any;
  isSubscribePage: boolean;
  panelData: any;
  series: any;
  currentEpisodeID: string;
  subscriptionBadge: string;

  constructor(
    private router: Router,
    private location: Location,
    private newComicService: NewComicService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private comicService: ComicService,
    private readonly route: ActivatedRoute,
    private notificationService: NotificationService,
    private panelNavService: PanelNavService
  ) {
    this.checkAndUpdatePageStatus();
  }

  ngOnInit() {
    this.isAnonymous = isAnonymousUser();
    this.isMobile = this.newComicService.isMobileView();
    this.currentURL = this.comicService.getCurrentUrl();
    this.isStoryPage = this.newComicService.isStoryPage();

    this.activeIcon =
      (this.location.path() === '' &&
        this.localStorageService.getItem('selectedMenuItem') === 'following')
        ? '/following'
        : this.location.path();
    this.getUserDetails();
    this.newComicService.getsidebarItemSelected().subscribe((val) => {
      if (val && !val.sameItemClicked) {
        if (val && val.menuItem === 'home') {
          this.localStorageService.setItem('isComingFromFollowing', false);    
        } else if (val && val.menuItem === 'following') {
          this.localStorageService.setItem('isComingFromFollowing', true);
        }
        this.activeIcon = this.localStorageService.getItem('selectedMenuItem') === 'home' ? '' : '/following' ;
      }
    }, err => {
      this.activeIcon = '';
    })

    // refresh login data on successful signin
    this.newComicService.getRefreshLoginDataEvent().subscribe(() => {
      this.getUserDetails();
    });

    // code to handle same menu click for home and following page for smart scrolling purpose
    if (!this.previousItem) {
      if (['', '/'].includes(this.activeIcon)) {
        this.previousItem = 'home';
      }
      if (this.activeIcon === '/following' || (this.localStorageService.getItem('showAllComics') === false)) {
        // showAllComics's value in localstorage can be null or undefined, that's why we need exact match
        this.previousItem = 'tickbox';
      }
      if (this.activeIcon === 'directory') {
        this.previousItem = 'directory';
      }
    }
    this.setUnseenNotificationCount();

    this.subjectHandlers();
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  private setUnseenNotificationCount() {
    this.notificationService.getNotification(1, '', true).then(res => {
      if (this.activeIcon !== '/notifications' && res.unReadCount > 0) {
        this.unreadCount = Number(res.unReadCount) > 99 ? `99+` : String(res.unReadCount);
      } else {
        this.unreadCount = '0';
        this.notificationService.updateLastSeenNotificationAt(Date.now());
      }
    });
  }

  public getUserDetails() {
    this.userDetailsLoading = true;
    this.comicService.getUserDetails().then(res => {
      if (this.isSeriesPage || this.isComicPage) { // to show/hide "+ Follow" button on bottom nav
        const currentSeries = this.getSeriesNameFromURL(window.location.pathname);
        this.isUserFollowingCurrentSeries = !!res.data.alerts[currentSeries];
      }
      this.isIncompleteProfile = !this.isAnonymous && (!res.data.displayName || !res.data.gender);
      this.setUserDetails(res);
      this.userDetailsLoading = false;
    }, () => {
      this.userDetailsLoading = false;
    });
  }

  public navigateTo(icon: string, event?: any): void {
    event && event.preventDefault(); // to stop it from reloading the page as anchor tag does this sometimes
    // code to handle same menu click for home and following page for smart scrolling purpose
    const sameItemClicked = this.previousItem === icon;
    if (sameItemClicked && !['tickbox', 'homeOrFollowing', 'home', 'directory', 'back', 'share', 'open-link', 'next-comic', 'previous-comic', 'view-comic', 'series-home', 'follow', 'shop', 'subscribe', 'sign-in'].includes(icon)) {
      return;
    }

    if (!['shop'].includes(icon)) {
      this.activeIcon = icon;
    }
    // For navigation
    switch (icon) {
      case 'home': 
        this.activeIcon = '';
        this.localStorageService.setItem('selectedMenuItem', 'home');
        this.newComicService.setsidebarItemSelected({ menuItem: 'home', sameItemClicked: sameItemClicked });
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.comicService.updateUserSettings({showAllComics: true});
        this.router.navigate(['']);
        break;
      case 'homeOrFollowing':
        // if (this.isStoryPage || this.isComicPage || this.isSeriesPage) {
          // Navigating to following page, if he is coming from following page
          const menuItem = this.localStorageService.getItem('selectedMenuItem');
          // if (isComingFromFollowing) {
          //   this.newComicService.setsidebarItemSelected({ menuItem: 'following', sameItemClicked: sameItemClicked });
          //   this.activeIcon = '/following';
          //   if (sameItemClicked) return; // stopping navigation if same following is clicked
          //   this.router.navigate(['']);
          //   break;
          // }
        // }
        this.activeIcon = menuItem === 'home' ? '' : '/following'; 
        this.newComicService.setsidebarItemSelected({ menuItem, sameItemClicked: false });
        if (sameItemClicked) return; // stopping navigation if same home is clicked
        this.router.navigate(['']);
        break;
      case 'directory':
        this.newComicService.setsidebarItemSelected({ menuItem: 'directory', sameItemClicked: sameItemClicked });
        this.activeIcon = 'directory';
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.router.navigate(['/tinyview/comic-series-directory']);
        break;
      case 'tickbox':
        this.localStorageService.setItem('selectedMenuItem', 'following');
        this.newComicService.setsidebarItemSelected({ menuItem: 'following', sameItemClicked: sameItemClicked });
        this.activeIcon = '/following';
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.comicService.updateUserSettings({showAllComics: false});
        this.router.navigate(['']);
        break;
      case 'notification':
        // Call API: updateLastSeenNotificationAt
        this.notificationService.updateLastSeenNotificationAt(Date.now());
        this.router.navigate(['/notifications']);
        break;
      case 'back':
        this.goBack();
        break;
      case 'series-home':
        let seriesName = this.seriesHome;
        if (this.isComicPage) {
          seriesName = this.getSeriesNameFromURL(window.location.pathname);
        }
        this.router.navigate([seriesName]);
        break;
      case 'view-comic':
        const data = {
          id: this.currentEpisodeID,
          action: this.currentComicAction
        }
        this.comicNavigation(data);
        break;
      case 'open-link':
        this.openLinkInNewTab(this.currentComicAction);
        break;
      case 'next-comic':
        this.comicNavigation(this.btnNavigation.nextComic);
        break;
      case 'previous-comic':
        this.comicNavigation(this.btnNavigation.prevComic);
        break;
      case 'share':
        this.newComicService.triggerShareModal();
        break;
      case 'follow':
        this.newComicService.triggerFollowSeriesSubject();
        break;
      case 'shop':
        this.openLinkInNewTab(TINYVIEW_SHOP);
        break;
      case 'subscribe':
        this.router.navigate([subscribeURL]);
        break;
      case 'sign-in':
        this.router.navigate([signInPhoneURL]);
        break;
      default:
        this.newComicService.setsidebarItemSelected('home');
        this.comicService.updateUserSettings({showAllComics: false});
        this.router.navigate(['']);
        break;
    }

    this.previousItem = icon;
  }

  public getIconUrl(icon: string): string {
    switch (icon) {
      case 'directory':
      case '/tinyview/comic-series-directory':
        return ([icon, '/tinyview/comic-series-directory'].includes(this.activeIcon)) ? this.iconUrlsDark['directory'] : this.iconUrls['directory']
        break;
      case '/following':
        return this.activeIcon === icon ? this.iconUrlsDark['tickbox'] : this.iconUrls['tickbox']
        break;
      case '/account':
        return this.activeIcon === icon ? this.iconUrlsDark['user'] : this.iconUrls['user']
        break;
      case '/notifications':
        return this.activeIcon === icon ? this.iconUrlsDark['notification'] : this.iconUrls['notification']
        break;
      case 'back':
        return this.iconUrls['back'];
        break;
      case 'share':
        return this.iconUrls['share'];
        break;
      case 'previous-comic':
        return (this.disableBtn.prevComic) ? this.iconUrlsDark['previous-comic'] : this.iconUrls['previous-comic'];
        break;
      case 'next-comic':
        return (this.disableBtn.nextComic) ? this.iconUrlsDark['next-comic'] : this.iconUrls['next-comic'];
        break;
      case 'series-home':
        return this.iconUrls['series-home'];
        break;
      case 'shop':
        return this.iconUrls['shop'];
        break;
      default:
        return this.activeIcon === '' || this.activeIcon === '/following' ? this.iconUrlsDark['home'] : this.iconUrls['home']
        break;
    }
  }

  ///  For Old slider
  public outsideClick(hasClickedOutside) {
    if (this.menuState == 'in') {
      this.name = hasClickedOutside ? 'clicked inside' : 'clicked outside';
      if (this.name == 'clicked outside') {
        this.menuState = 'out'
      }
    }
  }

  private setUserDetails(user) {
    this.userImage = user.data.photoURL;
    this.userName = user.data.displayName;
    this.userBadges = user.data.badges;
    this.userPhoneNumber = user.data.phoneNumber ? user.data.phoneNumber.substr(user.data.phoneNumber.length - 4) : null;
    this.subDetails = user.data.subscriptions.length && user.data.subscriptions[0];
    if (this.subDetails) {
      this.isSubscribed = this.subDetails ? true : false;
      // Reading Subscriber badge from badges array, for correct order of subscriptions
      const subsType = this.getFirstSubscriberBadge(user.data.badges);
      this.subscriptionBadge = this.comicService.mapUserBadge(subsType, 100);
    }
  }

  private getFirstSubscriberBadge(badges: string[]): string | undefined {
    return badges.find(badge => badge.startsWith("subscriber-"));
  }

  public toggleMenu() {
    this.menuState = this.menuState == 'out' ? 'in' : 'out';
    this.clickedOutside = false;
  }

  public parentCall() {
    this.chviewChild.ngOnInit();
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymous ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  public goBack(): void {
    // 3 for mobile and 2 for desktop
    if ((window.history.length <= 3) || this.route.snapshot.queryParams.showSubscriptionMsg) {
      // If history length is 3 or less, navigate to home page
      this.router.navigate(['/']);
    } else {
      // Otherwise, go back
      this.location.back();
    }
  }

  // Do not use this function on story page, it is only meant for series and comic pages
  public getSeriesNameFromURL(url) {
    const pathName = window.location.pathname;  // Gets the current URL path, e.g., "/the-other-end/2024/07/11/a-brief-history-of-onions"
    return pathName.split('/')[1];  // Splits the path by '/' and extracts the second part (index 1), which is "the-other-end"
  }

  public subjectHandlers() {
    // Subscribe to data changes
    if (this.isSeriesPage) {
      this.newComicService.continueReadingData$.subscribe(data => {
        this.continueReadingData = data;
        if (this.continueReadingData && this.continueReadingData.isFirstEpisode) {
          this.episodeBtnCta = 'First Episode';
        } else {
          this.episodeBtnCta = 'Next Episode';
        }
      });
    }

    this.newComicService.followLoader$.subscribe(data => {
      if (data) {
        this.followBtnLoading = data.isLoading;
        this.isUserFollowingCurrentSeries = data.isFollowing;
      }
    });

    this.newComicService.episodesList$.subscribe(data => {
      this.seriesEpisodes = data.episodes;
      this.series = data.series;
      this.currentEpisodeID = data.currentEpisodeID;

      if (this.isStoryPage) {
        this.currentComicAction = data.currentComicAction;
        this.isExternalLink = this.newComicService.isExternalImage(this.currentComicAction);
        this.seriesHome = data.seriesHome;
      }

      if (this.seriesEpisodes && this.seriesEpisodes.length && (this.isComicPage || this.isStoryPage)) {
        this.setPreviousAndNextComic();
      }

      // for story page only
      if (this.isExternalLink) {
        try {
          const domain = new URL(this.currentComicAction) && new URL(this.currentComicAction).hostname && new URL(this.currentComicAction).hostname.replace(/^www\./, '');
          this.externalBtnCta = this.comicService.isVideoWebsiteDomain(domain || this.currentComicAction);
        } catch (e) {
          this.externalBtnCta = 'Open Link';
        }
      }
    });

    // Subscribe to the panel-nav state
    this.panelNavService.panelNavState$.subscribe(panelData => {
      if (state) {
        this.panelData = panelData;
      }
    });
  }

  // Handle the doLike event and pass it to the panel-nav.service
  onDoLike(event: any) {
    this.panelNavService.emitLike(event);
  }

  public navigateToNextComic(continueReadingData: any) {
    if (continueReadingData.actionType != "website") {
      this.router.navigate([continueReadingData.action.replace('index.json', '')])
    } else {
      this.router.navigate([`/story/${continueReadingData.storyID}`]);
    }
  }

  setPreviousAndNextComic() {
    const index = this.seriesEpisodes.findIndex(episodes => episodes.id.indexOf(this.currentEpisodeID) > -1);

    // Previous Comic
    this.disableBtn.prevComic = (index > -1) ? !!!this.seriesEpisodes[index - 1] : true;
    this.btnNavigation.prevComic = !this.disableBtn.prevComic ? this.seriesEpisodes[index - 1] : null;

    // Next Comic
    this.disableBtn.nextComic = (index > -1) ? !!!this.seriesEpisodes[index + 1] : true;
    this.btnNavigation.nextComic = !this.disableBtn.nextComic ? this.seriesEpisodes[index + 1] : null;
  }


  public comicNavigation(comicData) {
    let actionURL = comicData.action
    if (!actionURL) return;

    if (this.newComicService.isExternalImage(actionURL)) {
      this.router.navigate([`/story/${comicData.id}`]);
    } else {
      actionURL = actionURL.replace('/index.json', '');
      this.router.navigate([actionURL]);
    }
  }

  async checkAndUpdatePageStatus() {
    // Immediately update the values the first time
    this.isSeriesPage = await this.comicService.isSeriesPage() || this.comicService.isTinyviewSeriesPage();
    this.isSeriesPage && this.subjectHandlers();
    this.isStoryPage = this.comicService.isStoryPage();
    this.isSubscribePage = this.newComicService.isSubscribePage();
    this.isComicPage = this.comicService.isComicPage() && !this.newComicService.isInfluencePointsPage() && !this.isSubscribePage && !this.newComicService.isSignUpNewPage() && !this.newComicService.isSignInPage() && !this.newComicService.isVerificationScreen();
    this.isHomePage = this.newComicService.isHomePage();

    if (!this.isSeriesPage && !this.isComicPage && !this.isStoryPage) {
      let counter = 0;
      const intervalId = setInterval( async() => {
        // Update the values every second
        this.isSeriesPage = await this.comicService.isSeriesPage();
        this.isStoryPage = this.comicService.isStoryPage();
        this.isSubscribePage = this.newComicService.isSubscribePage();
        this.isComicPage = this.comicService.isComicPage() && !this.newComicService.isInfluencePointsPage() && !this.isSubscribePage && !this.newComicService.isSignUpNewPage() && !this.newComicService.isSignInPage() && !this.newComicService.isVerificationScreen();
        this.isHomePage = this.newComicService.isHomePage();
    
        counter++; // Increment the counter each time
    
        if (counter >= 10) { // After 10 executions
          clearInterval(intervalId); // Stop the interval
        }
      }, 1000); // Run every 1 second
    }
  }

  openLinkInNewTab(link) {
    this.comicService.viewsForExternalLink(link, 1, '', this.seriesHome);
    window.open(link, '_blank');
  }
}

import { environment } from 'src/environments/environment';

const ASSETS_IMAGES: { [key: string]: string } = {
  'SIGNPOST': '/assets/images/new-icons/signpost-100.png',
  'SIGNPOST_DARK': '/assets/images/new-icons/signpost-100-2.png',
  'TICKBOX': '/assets/images/new-icons/tick-box-100.png',
  'TICKBOX_DARK': '/assets/images/new-icons/tick-box-100-2.png',
  'HOME': '/assets/images/new-icons/home-100.png',
  'HOME_DARK': '/assets/images/new-icons/home-100-2.png',
  'USER': '/assets/images/new-icons/user-menu-male-100.png',
  'USER_DARK': '/assets/images/new-icons/user-menu-male-100-2.png',
  'ALARM': '/assets/images/new-icons/alarm-100.png',
  'ALARM_DARK': '/assets/images/new-icons/alarm-100-2.png',
  'RED_HEART': '/assets/images/red-heart-100.png',
  'HEART_OUTLINE': '/assets/images/heart-outline-100.png?v=1',
  'HEART_OUTLINE_FILLED': '/assets/images/heart-outline-filled-100.png?v=1',
  'ICONS8_LIST': '/assets/images/new-icons/icons8-list-100.png',
  'ICONS8_NEWS': '/assets/images/new-icons/icons8-news-100.png',
  'ICONS8_SIGN_IN': '/assets/images/new-icons/icons8-sign-in-100.png',
  'MALE_USER': '/assets/images/male-user-100.png',
  'ICONS8_ADD_USER_GROUP_MAN': '/assets/images/new-icons/icons8-add-user-group-man-man-100.png',
  'ICON_EYE': '/assets/images/new-icons/icons8-eye-96.png',
  'ICON8_GOOD_NOTES': '/assets/images/new-icons/icons8-goodnotes-100.png',
  'ICONS8_TERMS_AND_CONDITIONS': '/assets/images/new-icons/icons8-terms-and-conditions-100.png',
  'ICONS8_RENEW': '/assets/images/new-icons/icons8-renew-100.png',
  'ICONS8_PRIVACY_POLICY': '/assets/images/new-icons/icons8-privacy-policy-100.png',
  'ICONS8_CANCEL_SUBSCRIPTION': '/assets/images/new-icons/icons8-cancel-subscription-100.png',
  'TINYVIEW_INFLUENCE_POINTS_OUTLINE': '/assets/images/new-icons/tinyview-influence-points-outline-100-444444.png',
  'ICONS8_MAILING': '/assets/images/new-icons/icons8-mailing-100.png',
  'ICONS8_PAINT_PALETTE': '/assets/images/new-icons/icons8-paint-palette-50.png',
  'ICONS8_ADD': '/assets/images/new-icons/icons8-add-50.png',
  'ICONS8_ADD_DARK': '/assets/images/new-icons/icons8-add-50-2.png',
  'ICONS8_EDIT': '/assets/images/new-icons/icons8-edit-50.png',
  'ICONS8_EDIT_DARK': '/assets/images/new-icons/icons8-edit-50-2.png',
  'ICONS8_ADD_LINK': '/assets/images/new-icons/icons8-add-link-50.png',
  'ICONS8_ADD_LINK_DARK': '/assets/images/new-icons/icons8-add-link-50-2.png',
  'ICONS8_ADD_REMINDER': '/assets/images/new-icons/icons8-add-reminder-50.png',
  'ICONS8_ADD_REMINDER_DARK': '/assets/images/new-icons/icons8-add-reminder-50-2.png',
  'ICONS8_COMBO_CHART': '/assets/images/new-icons/icons8-combo-chart-50.png',
  'ICONS8_COMBO_CHART_DARK': '/assets/images/new-icons/icons8-combo-chart-50-2.png',
  'ICONS8_EXPAND_ARROW': '/assets/images/new-icons/icons8-expand-arrow-100.png',
  'BACK_ARROW': '/assets/images/new-icons/back-arrow-100.png',
  'ICONS8_USER_ACCOUNT': '/assets/images/new-icons/icons8-user-account-100-2.png',
  'ICONS8_REGISTRATION': '/assets/images/new-icons/icons8-registration-100.png',
  'ICONS8_PAYROLL': '/assets/images/new-icons/icons8-payroll-100.png',
  'ICONS8_PAYROLL_DARK': '/assets/images/new-icons/icons8-payroll-100-2.png',
  'ICONS8_SETTINGS': '/assets/images/new-icons/icons8-settings-100.png',
  'ICON8_GOOD_NOTES_DARK': '/assets/images/new-icons/icons8-goodnotes-100-2.png',
  'ICONS8_TERMS_AND_CONDITIONS_DARK': '/assets/images/new-icons/icons8-terms-and-conditions-100-2.png',
  'ICONS8_RENEW_DARK': '/assets/images/new-icons/icons8-renew-100-2.png',
  'ICONS8_PRIVACY_POLICY_DARK': '/assets/images/new-icons/icons8-privacy-policy-100-2.png',
  'TINYVIEW_INFLUENCE_POINTS_FILLED': '/assets/images/new-icons/tinyview-influence-points-filled-100-444444.png',
  'ICONS8_MAILING_DARK': '/assets/images/new-icons/icons8-mailing-100-2.png',
  'ICONS8_PAINT_PALETTE_DARK': '/assets/images/new-icons/icons8-paint-palette-50-2.png',
  'ICONS8_COLLAPSE_ARROW': '/assets/images/new-icons/icons8-collapse-arrow-100.png',
  'ICONS8_REGISTRATION_100_FILLED': '/assets/images/new-icons/icons8-registration-100-filled.png',
  'ICONS8_BOX_IMPORTANT': '/assets/images/new-icons/icons8-box-important-100.png',
  'ICONS8_RIGHT_ARROW': '/assets/images/new-icons/icons8-right-arrow-100.png',
  'PENCIL_ICON': '/assets/images/pencil-icon.png',
  'LIKE_ICON': '/assets/images/new-icons/like.png',
  'ICONS8_FACEBOOK': '/assets/images/new-icons/icons8-facebook-100-3.png',
  'ICONS8_TWITTERX': '/assets/images/new-icons/icons8-twitterx-100-outline.png',
  'ICONS8_DELETE': '/assets/images/new-icons/icons8-delete-100.png',
  'ICONS8_SHARE': '/assets/images/new-icons/icons8-share-100-3.png',
  'TINYVIEW_LOGO_ONLY_MARK': '/assets/images/tinyview-logo-only-mark-square-rounded.png',
  'ICONS8_SIGN_OUT': '/assets/images/new-icons/icons8-sign-out-100-2.png',
  'DIRECTORY_ICON_DISABLED': '/assets/images/directory-icon-disabled.png',
  'HOME_ICON': '/assets/images/home-icon.png',
  'RELOAD': '/assets/images/reload.png',
  'DIRECTORY_ICON': '/assets/images/directory-icon.png',
  'ICONS8_QUOTE': '/assets/images/new-icons/icons8-quote-50.png',
  'TINYVIEW_MARK_AND_LOGO': '/assets/images/new-icons/tinyview-mark-and-logo-400.png',
  'ICONS8_COMMENT': '/assets/images/new-icons/icons8-comment-100.png',
  'ICONS8_LINK': '/assets/images/new-icons/icons8-link-100.png',
  'ICONS8_INSTAGRAM_100_2': '/assets/images/new-icons/icons8-instagram-100-2.png',
  'COMIC_HEADER': '/assets/images/comic-header.gif',
  'LISTVIEW_PLACEHOLDER': '/assets/images/new-icons/listview-placeholder.gif',
  'CONTENT_PLACEHOLDER': '/assets/images/content-placeholder.gif',
  'ICONS8_LOADING_V2': '/assets/images/new-icons/icons8-loading-v2.gif',
  'BADGE_COFFEE_100': '/assets/images/badge-coffee-100.png',
  'ADD_USER_GROUP_MAN_100': '/assets/images/add-user-group-man-100.png',
  'TINYVIEW_INFLUENCE_POINTS_OUTLINE_100': '/assets/images/new-icons/tinyview-influence-points-outline-100.png',
  'PAGE_TOP_PLACEHOLDER': '/assets/images/new-icons/page-top-placeholder.gif',
  'ICONS8_COLLAPSE_ARROW_50_2': '/assets/images/new-icons/icons8-collapse-arrow-50-2.png',
  'ICONS8_BACK_50': '/assets/images/new-icons/icons8-back-50.png',
  'ICONS8_FORWARD_50': '/assets/images/new-icons/icons8-forward-50.png',
  'ICONS8_EXPAND_ARROW_50_3': '/assets/images/new-icons/icons8-expand-arrow-50-3.png',
  'EPISODE_LEFT_ARROW': '/assets/images/episode-left-arrow.png',
  'EPISODE_RIGHT_ARROW': '/assets/images/episode-right-arrow.png',
  'LEFT_ARROW_DISABLED': '/assets/images/left-arrow-disabled.png',
  'ICONS8_RED_BULLETED_LIST_25': '/assets/images/icons8-red-bulleted-list-25.png',
  'ICONS8_GREY_BULLETED_LIST_25': '/assets/images/icons8-grey-bulleted-list-25.png',
  'ICONS8_FACEBOOK_WHITE_50': '/assets/images/icons8-facebook-white-50.png',
  'ICONS8_TWITTER_WHITE_50': '/assets/images/icons8-twitter-white-50.png',
  'LEFT_START_ARROW_100': '/assets/images/new-icons/left-start-arrow-100.png',
  'WHITE_LEFT_START_ARROW_100': '/assets/images/new-icons/white-left-start-arrow-100.png',
  'WHITE_LEFT_100': '/assets/images/new-icons/white-left-100.png',
  'WHITE_RIGHT_100': '/assets/images/new-icons/white-right-100.png',
  'RIGHT_END_ARROW_100': '/assets/images/new-icons/right-end-arrow-100.png',
  'WHITE_RIGHT_END_ARROW_100': '/assets/images/new-icons/white-right-end-arrow-100.png',
  'ICONS8_EXTERNAL_LINK_100': '/assets/images/new-icons/icons8-external-link-100.png',
  'ICONS8_SORT_LEFT_100': '/assets/images/new-icons/icons8-sort-left-100.png',
  'POST_COMMENT_ICON': '/assets/images/post_comment_icon.png',
  'ICONS8_INFORMATION_50': '/assets/images/new-icons/icons8-information-50.png',
  'ICONS8_EXCLAMATION_100': '/assets/images/new-icons/icons8-exclamation-100.png',
  'FRIENDS_PLACEHOLDER': '/assets/images/new-icons/friends-placeholder.gif',
  'NOTIFICATIONS_PLACEHOLDER': '/assets/images/new-icons/notifications-placeholder.gif',
  'NO_NOTIFICATION': '/assets/images/new-icons/no-notification.png',
  'ICONS8_MALE_USER_100': '/assets/images/new-icons/icons8-male-user-100.png',
  'ICONS8_CHECKMARK_100': '/assets/images/new-icons/icons8-checkmark-100.png',
  'APPLE_MESSAGES_100': '/assets/images/apple-messages-100.png',
  'GOOGLE_MESSAGES_100': '/assets/images/google-messages-100.png',
  'WHATSAPP_100': '/assets/images/whatsapp-100.png',
  'INSTAGRAM_100': '/assets/images/instagram-100.png',
  'FACEBOOK_100': '/assets/images/facebook-100.png',
  'ICONS8_TWITTERX_DARK_100_CROPPED': '/assets/images/new-icons/icons8-twitterx-dark-100-cropped.png',
  'COPY_LINK_PINK_100': '/assets/images/copy-link-pink-100.png',
  'OPTIONS_100': '/assets/images/options-100.png',
  'ICONS8_COPY_50': '/assets/images/icons8-copy-50.png',
  'ICONS8_SETTINGS_50': '/assets/images/icons8-settings-50.png',
  'ICONS8_TRASH_CAN_50': '/assets/images/icons8-trash-can-50.png',
  'ICONS8_REPLACE_50': '/assets/images/icons8-replace-50.png',
  'TINYVIEW_LOGO': '/assets/images/tinyview-logo.png',
  'USER_ICON': '/assets/images/user_icon.png',
  'BADGE_TINYVIEW_100': '/assets/images/badge-tinyview-100.png',
  'INSTALL_APP__Q_R_CODE.JPEG': '/assets/images/new-icons/install-app-QR-code.jpeg',
  'ICONS8_APP_STORE_100': '/assets/images/new-icons/icons8-app-store-100.png',
  'DOWNLOAD_ON_THE_APP_STORE': '/assets/images/new-icons/download-on-the-app-store.svg',
  'ICONS8_GOOGLE_PLAY_100': '/assets/images/new-icons/icons8-google-play-100.png',
  'GOOGLE_PLAY_BADGE': '/assets/images/new-icons/google-play-badge.png',
  'FACEBOOK': '/assets/images/facebook.svg',
  'INSTAGRAM': '/assets/images/instagram.svg',
  'TWITTER': '/assets/images/twitter.svg',
  'ICONS8_DOWN_BUTTON_50': '/assets/images/icons8-down-button-50.png',
  'IMAGE_DISABLED_DOWN': '/assets/images/image-disabled-down.png',
  'ICONS8_SLIDE_UP_50': '/assets/images/icons8-slide-up-50.png',
  'IMAGE': '/assets/images/image.png',
  'SHARE': '/assets/images/share.png',
  'ICONS8_CLOSE_100': '/assets/images/new-icons/icons8-close-100.png',
  'ICONS8_BACK_ARROW_100': '/assets/images/new-icons/icons8-back-arrow-100.png',
  'PIZZA': '/assets/images/pizza-100.png',
  'COFFEE': '/assets/images/coffee-100.png',
  'ARTSUPPLIES': '/assets/images/artsupplies-100.png',
  'BAGEL': '/assets/images/bagel-100.png',
  'COOKIE': '/assets/images/cookie-100.png',
  'BACK_ARROW_ICON': '/assets/images/back-arrow-icon.png',
  'MSTILE_150X150': '/assets/images/mstile-150x150.png',
  'ICONS8_BACK_150': '/assets/images/new-icons/icons8-back-150.png',
  'ICONS8_CHECKMARK_60': '/assets/images/new-icons/icons8-checkmark-60.png',
  'ICONS8_COMMENT_100': '/assets/images/new-icons/icons8-comment-100.png',
  'ICONS8_DOUBLE_TICK_100_DARK': '/assets/images/new-icons/icons8-double-tick-100-dark.png',
  'ICONS8_DOUBLE_TICK_100_WHITE': '/assets/images/new-icons/icons8-double-tick-100-FFFFFF.png',
  'ICONS8_DOUBLE_TICK_100': '/assets/images/new-icons/icons8-double-tick-100.png',
  'ICONS8_EYE_96': '/assets/images/new-icons/icons8-eye-96.png',
  'ICONS8_LIST_96': '/assets/images/new-icons/icons8-list-96.png',
  'ICONS8_NEXT_90': '/assets/images/new-icons/icons8-next-90.png',
  'ICONS8_PLUS_MATH_50': '/assets/images/new-icons/icons8-plus-math-50.png',
  'ICONS8_PREV_90': '/assets/images/new-icons/icons8-prev-90.png',
  'ICONS8_SHARE_100': '/assets/images/new-icons/icons8-share-100.png',
  'ICONS8_SHARE_ROUNDED_100': '/assets/images/new-icons/icons8-share-rounded-100.png',
  'ICONS8_UPLOAD_150': '/assets/images/new-icons/icons8-upload-150.png',
  'PREVIEW_IMAGE_NOT_AVAILABLE': '/assets/images/new-icons/preview-image-not-available.jpg',
  'RED_HEART_100': '/assets/images/red-heart-100.png',
  'ICONS8_ALARM_100_2': '/assets/images/new-icons/icons8-alarm-100-2.png',
  'ICONS8_ALARM_100': '/assets/images/new-icons/icons8-alarm-100.png',
  'ICONS8_BACK_100': '/assets/images/new-icons/icons8-back-100.png',
  'ICONS8_BOX_IMPORTANT_100_LOGO_RED': '/assets/images/new-icons/icons8-box-important-100-logo-red.png',
  'ICONS8_COMMENTS_100': '/assets/images/new-icons/icons8-comments-100.png',
  'ICONS8_EXTERNAL_LINK_LIGHT_100': '/assets/images/new-icons/icons8-external-link-light-100.png',
  'ICONS8_EYE_100': '/assets/images/new-icons/icons8-eye-100.png',
  'ICONS8_EYE_THIN_100': '/assets/images/new-icons/icons8-eye-thin-100.png',
  'ICONS8_HEART_100': '/assets/images/new-icons/icons8-heart-100.png',
  'ICONS8_HOME_100_2': '/assets/images/new-icons/icons8-home-100-2.png',
  'ICONS8_HOME_100': '/assets/images/new-icons/icons8-home-100.png',
  'ICONS8_LESS_THAN_100': '/assets/images/new-icons/icons8-less-than-100.png',
  'ICONS8_LIST_100_626262': '/assets/images/new-icons/icons8-list-100-626262.png',
  'ICONS8_PLAY_100_626262': '/assets/images/new-icons/icons8-play-100-626262.png',
  'ICONS8_PLAY_100': '/assets/images/new-icons/icons8-play-100.png',
  'ICONS8_REWIND_100_626262': '/assets/images/new-icons/icons8-rewind-100-626262.png',
  'ICONS8_REWIND_100': '/assets/images/new-icons/icons8-rewind-100.png',
  'ICONS8_SHOPPING_BAG_100_2': '/assets/images/new-icons/icons8-shopping-bag-100-2.png',
  'ICONS8_SHOPPING_BAG_100_DARK': '/assets/images/new-icons/icons8-shopping-bag-100-dark.png',
  'ICONS8_SHOPPING_BAG_100': '/assets/images/new-icons/icons8-shopping-bag-100.png',
  'ICONS8_SIGNPOST_100_2': '/assets/images/new-icons/icons8-signpost-100-2.png',
  'ICONS8_SIGNPOST_100': '/assets/images/new-icons/icons8-signpost-100.png',
  'ICONS8_UPLOAD_100': '/assets/images/new-icons/icons8-upload-100.png',
  'ICONS8_GIFT_MEDIUM_100': '/assets/images/new-icons/icons8-gift-100-medium-FFFFFF.png',
  'ICONS8_USER_MENU_MALE_100': '/assets/images/new-icons/icons8-user-menu-male-100.png',
  'ICONS8_ADD_100': '/assets/images/new-icons/icons8-add-100.png',
  'ICONS8_SENT_48': '/assets/images/new-icons/icons8-sent-48.png',
  'ICONS8_CHECKMARK_100_GREEN': '/assets/images/new-icons/icons8-checkmark-100 green.png',
  'ICONS8_CHEVRON_100_DARK': '/assets/images/new-icons/icons8-chevron-100-3D3D3D.png',
  'ICONS8_CROWN_100_FILLED': '/assets/images/new-icons/icons8-crown-filled-3D3D3D.png',
  'ICONS8_CROWN_100_OUTLINED': '/assets/images/new-icons/icons8-crown-outline-3D3D3D.png',
  'ICONS8_FREE_100_FILLED': '/assets/images/new-icons/icons8-free-filled-100-3D3D3D.png',
  'ICONS8_FREE_100_OUTLINED': '/assets/images/new-icons/icons8-free-100-3D3D3D.png',
  'ICONS8_STRIPE_100_DARK': '/assets/images/new-icons/icons8-stripe-100-3D3D3D.png',
  'ICONS8_GOOGLE_100_DARK': '/assets/images/new-icons/icons8-google-100-3D3D3D.png',
  'ICONS8_APPLE_100_DARK': '/assets/images/new-icons/icons8-apple-100-3D3D3D.png',
  'ICONS8_CROWN_100_DARK': '/assets/images/new-icons/icons8-crown-100-3D3D3D.png',
}

const CDN_IMAGES: { [key: string]: string } = {
  'TINYVIEW_PROFILE': '/tinyview/tinyview-profile.jpg'
}

const CDN_BUCKET = `${environment.API_END_POINT}`;
const ASSETS_BUCKET = `${environment.IMAGE_END_POINT}`;

export const getImage = (imageKey: string) => {
  if (ASSETS_IMAGES[imageKey]) { // all asset image should be placed in tinyview-dev-images bucket on GCP
    // console.log(`${ASSETS_BUCKET}${ASSETS_IMAGES[imageKey]}`);
    return `${ASSETS_BUCKET}${ASSETS_IMAGES[imageKey]}`;
  } else if (CDN_IMAGES[imageKey]) {
    // console.log(`${CDN_BUCKET}${CDN_IMAGES[imageKey]}`);
    return `${CDN_BUCKET}${CDN_IMAGES[imageKey]}`;
  } else {
    throw new Error('Image not found: ' + imageKey);
  }
};

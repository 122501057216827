export const subscribeURL = '/tinyview/subscribe';
export const influencePointsURL = '/tinyview/influence-points';
export const signInPhoneURL = '/signin/phone/number';
export const signUpPhoneURL = '/signup/phone/number';
export const phoneVerificationURL = '/verification/phone/number';
export const phoneOTPVerificationURL = '/verification/phone/otp';
export const emailVerificationURL = '/verification/email';
export const emailSentForVerificationURL = '/verification/email-sent';
export const allSeriesURL = '/tinyview/comic-series-directory';
export const MANAGE_ALERTS = '/manage-alerts';

export const signUpURL = '/signup/dob';
export const absoluteSignUpURL = '/signup';
export const upgradeTitle = 'Upgrade';
export const latestComics = 'Latest Comics';
export const TINYVIEW = 'tinyview';
export const templateTypes = {
  bonus: 'bonus',
  directory: 'directory',
  episodes: 'episodes',
  newsfeed: 'newsfeed',
  story: 'story',
  stories: 'stories',
  toc: 'toc',
  carousel: 'carousel',
  list: 'list',
  premium: 'premium',
  comicLimit: 'comicLimit',
  month: 'month',
  card: 'card'
};

export const carouselType = {
  episodes: 'episodes',
  home: 'home',
  following: 'following',
  list: 'list',
  continueReading: 'continueReading',
  otherSeries: 'otherSeries'
}
export const BROWSER = { DESKTOP: 'desktopBrowser', ANDROID: 'androidBrowser', IOS: 'iosBrowser', WINDOWS: 'windowsBrowser' };

export const traceStates = {
  STATE_UNSPECIFIED: 0,
  INITIALIZED: 1,
  STARTED: 2,
  COMPLETED: 3
};

export const youtubeDomains = [
  'googlevideo.com',
  'gvt1.com',
  'video.google.com',
  'video.l.google.com',
  'youtu.be',
  'youtube.com',
  'yt3.ggpht.com',
  'yt.be',
  'ytimg.com',
  'ytimg.l.google.com',
  'ytkids.app.goo.gl',
  'yt-video-upload.l.google.com',
  'vimeo.com'
];

export const stylesJson = {
  "styles": {
    "desktop": {
      "body": {
        "font-family": "system-ui, sans-serif"
      },
      "h1": {
        "font-weight": "700",
        "font-size": "22px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      "h2": {
        "font-weight": "700",
        "font-size": "18px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      "p": {
        "font-weight": "400",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#626262"
      },
      ".p-bold": {
        "font-weight": "700",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      ".body-mini": {
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "140%",
        "color": "#626262"
      },
      ".body-mini-bold": {
        "font-weight": "700",
        "font-size": "14px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      ".comments": {
        "font-family": "Georgia, serif",
        "font-style": "italic",
        "font-weight": "400",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#626262"
      }
    },
    "mobileWeb": {
      "body": {
        "font-family": "system-ui, sans-serif"
      },
      "h1": {
        "font-weight": "800",
        "font-size": "22px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      "h2": {
        "font-weight": "800",
        "font-size": "16px",
        "line-height": "140%",
        "color": "#3D3D3D"
      },
      "p": {
        "font-weight": "400",
        "font-size": "15px",
        "line-height": "120%",
        "color": "#626262"
      },
      ".p-bold": {
        "font-weight": "800",
        "font-size": "15px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      ".body-mini": {
        "font-weight": "400",
        "font-size": "13px",
        "line-height": "120%",
        "color": "#626262"
      },
      ".body-mini-bold": {
        "font-weight": "800",
        "font-size": "13px",
        "line-height": "120%",
        "color": "#3D3D3D"
      },
      ".comments": {
        "font-family": "Georgia, serif",
        "font-style": "italic",
        "font-weight": "400",
        "font-size": "15px",
        "line-height": "120%",
        "color": "#626262"
      }
    },
    "app": {
      "h1": {
        "fontFamily": "System",
        "fontSize": 24,
        "lineHeight": 34,
        "color": "#3D3D3D",
        "fontWeight": "800",
        "fontStyle": "normal"
      },
      "h2": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "800",
        "fontSize": 18,
        "lineHeight": 25,
        "color": "#3D3D3D"
      },
      "p": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "400",
        "fontSize": 16,
        "lineHeight": 19,
        "color": "#626262"
      },
      "pBold": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "800",
        "fontSize": 16,
        "lineHeight": 19,
        "color": "#3D3D3D"
      },
      "comments": {
        "fontFamily": "Georgia-italic",
        "fontStyle": "italic",
        "fontWeight": "400",
        "fontSize": 17,
        "lineHeight": 21,
        "color": "#626262"
      },
      "bodyMini": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "400",
        "fontSize": 14,
        "lineHeight": 17,
        "color": "#626262"
      },
      "bodyMiniBold": {
        "fontFamily": "System",
        "fontStyle": "normal",
        "fontWeight": "800",
        "fontSize": 14,
        "lineHeight": 17,
        "color": "#3D3D3D"
      }
    },
    "colors": {
      "logoRed": "#DF474B",
      "textBold": "#3D3D3D",
      "text": "#626262",
      "textInverse": "#FFFFFF",
      "separators": "#C4C4C4",
      "chatBubbles": "#E8E8E8",
      "borders": "#E8E8E8"
    }
  }
};

export const MONTHS_ARRAY = [
  { name: 'January', number: 1 },
  { name: 'February', number: 2 },
  { name: 'March', number: 3 },
  { name: 'April', number: 4 },
  { name: 'May', number: 5 },
  { name: 'June', number: 6 },
  { name: 'July', number: 7 },
  { name: 'August', number: 8 },
  { name: 'September', number: 9 },
  { name: 'October', number: 10 },
  { name: 'November', number: 11 },
  { name: 'December', number: 12 }
];

export const REDIRECTION_TYPES = [
  'request_accept',
  'story',
  'added_influence'
]

export const REFERRAL_CONSTS = {
  CREATOR: 'creator',
  SUBSCRIBER: 'subscriber',
  USER: 'user',
  REFERRAL: 'referral'
};

export const FBQ_TRACKING_EVENTS = {
  PURCHASE: 'Purchase',
  SUBSCRIPITON: 'Subscribe',
  SIGNUP: 'CompleteRegistration'
};

export const CREATOR_COL2_SUBHEAD = {
  subCol1: {
    value: 'Total',
    class: 'series-view-total'
  },
  subCol2: {
    value: 'Web',
    class: 'devices'
  },
  subCol3: {
    value: 'iOS',
    class: 'devices'
  },
  subCol4: {
    value: 'Android',
    class: 'devices'
  }
}

export const CREATOR_DASHBOARD_COLUMNS = {
  class: 'creator-row',
  col1: {
    value: 'Date',
    class: 'date-col'
  },
  col2: {
    value: 'Series Pageviews',
    class: 'series-views',
    subHead: CREATOR_COL2_SUBHEAD
  },
  col3: {
    value: 'Series %',
    class: 'series-col',
    tooltip: 'Series Pageviews as a percentage of Tinyview Pageviews.'
  },
  col4: {
    value: 'Pageviews Revenue',
    class: 'page-revenue',
    tooltip: 'Series Pageviews as a percentage of Tinyview Pageviews.'
  },
  col5: {
    value: 'Virtual Gifts',
    class: 'gift-col'
  },
  col6: {
    value: 'Series Revenue',
    class: 'series-revenue'
  }
}

export const REFERRAL_COL_2_SUBHEAD = {
  subCol1: {
    value: 'Referrals',
    class: 'referral'
  },
  subCol2: {
    value: 'Revenue',
    class: 'referral'
  },
}

export const REFERRAL_DASHBOARD_COLUMNS = {
  class: 'referral-row',
  col1: {
    value: 'Date',
    class: 'date-col'
  },
  col2: {
    value: 'Referral Revenue',
    class: 'referral-revenue',
    tooltip: 'Revenue generated by referrals',
    subHead: REFERRAL_COL_2_SUBHEAD
  },
}

export const TOP_REFERRER_DASHBOARD_COLUMNS = {
  class: 'top-referrer-row',
  col1: {
    value: 'Top Referrers',
    class: 'date-col'
  },
  col3: {
    value: 'Referrals',
    class: 'date-col',
  },
  col4: {
    value: 'Revenue',
    class: 'date-col',
  }
}

export const TOTALS_DASHBOARD_COLUMNS = {
  class: 'totals-row',
  col1: {
    value: '',
    class: 'totals-col totals-col-1'
  },
  col3: {
    value: '',
    class: 'totals-col',
  },
}

export const AUTH_TYPE = {
  EMAIL: 'email',
  PHONE: 'phone'
};

export const LOGIN_FLOW = {
  SIGNUP: 'SIGNUP',
  SIGNIN: 'SIGNIN',
  ALERTS: 'ALERTS',
  MERGE: 'MERGE',
  MERGED: 'MERGED',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export const PLATFORM_TYPE = {
  WEB: 'web',
  APP: 'app'
};

export const USER_TYPE = {
  ANONYMOUS: 'anonymous',
  ACTIVE: 'active'
};

export const MANAGE_ALERTS_MAPPING = {
  push: 'Push Notifications',
  email: 'Email',
  sms: 'SMS Text',
  publishComic: 'New content from series you follow',
  likeStory: 'A friend likes a post',
  likeComment: 'Some user likes your comment',
  commentOnStory: 'A friend comments on a post',
  commentOnStoryCreator: '[Creators] A user comments on your post',
  giftCreator: '[Creators] A user gifts you',
  giftUser: 'A friend gifts a creator',
  friendActivity: 'Friend network',
  flagComment: '[Creators] A user reports a comment',
  influencePoints: 'Influence points activity'
};

export const ACTIONS = [
  'manageAlerts'
];

export const ALLOWED_ACTION_TYPES = [
  'website'
];

export const API_KEYS = {
  NOTIFICATION_SCHEDULER: 'NOTIFICATION_SCHEDULER',
  GET_GIFTS_DASHBOARD_DATA_BY_DATE: 'GET_GIFTS_DASHBOARD_DATA_BY_DATE',
  GET_DASHBOARD_INVITE_DATA: 'GET_DASHBOARD_INVITE_DATA',
  DELETE_STORY: 'DELETE_STORY',
  EDIT_STORY: 'EDIT_STORY',
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  GET_REFFERER_DASHBOARD_DATA: 'GET_REFFERER_DASHBOARD_DATA',
  GET_CREATOR_DASHBOARD_DATA: 'GET_CREATOR_DASHBOARD_DATA',
  GET_SERIES_CAROUSEL: 'GET_SERIES_CAROUSEL',
  STORY_FROM_COMIC_WRITER: 'STORY_FROM_COMIC_WRITER',
  GET_MULTIPLE_PAGE_INFO: 'GET_MULTIPLE_PAGE_INFO',
  CREATE_PORTAL_LINK: 'CREATE_PORTAL_LINK',
  CREATE_CHECKOUT_LINK: 'CREATE_CHECKOUT_LINK',
  GET_SUBSCRIPTION_METER_AGGREGATES: 'GET_SUBSCRIPTION_METER_AGGREGATES',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  MERGE_AUTHENTICATED_USER: 'MERGE_AUTHENTICATED_USER',
  SEND_TEXT_MESSAGE: 'SEND_TEXT_MESSAGE',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_PROFILE_PIC_URL: 'UPDATE_PROFILE_PIC_URL',
  GET_UNLOCK_COMIC_URLS: 'GET_UNLOCK_COMIC_URLS',
  UPDATE_FRIEND_REQUEST: 'UPDATE_FRIEND_REQUEST',
  GET_FRIEND_REQUEST: 'GET_FRIEND_REQUEST',
  SEND_FRIEND_REQUEST: 'SEND_FRIEND_REQUEST',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_ALL_GIFT_ITEM: 'GET_ALL_GIFT_ITEM',
  ADD_INFLUENCE_POINT: 'ADD_INFLUENCE_POINT',
  REDEEM_INFLUENCE_POINT: 'REDEEM_INFLUENCE_POINT',
  GET_INFLUENCE_POINTS_FOR_ACTIONS: 'GET_INFLUENCE_POINTS_FOR_ACTIONS',
  GET_ALL_COMICS: 'GET_ALL_COMICS',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_USER_FEED: 'GET_USER_FEED',
  GET_SERIES_COMICS: 'GET_SERIES_COMICS',
  RECORD_STORY_VIEW: 'RECORD_STORY_VIEW',
  FETCH_COMMENTS: 'FETCH_COMMENTS',
  ADD_COMMENT: 'ADD_COMMENT',
  LIKE_COMMENT: 'LIKE_COMMENT',
  DISLIKE_COMMENT: 'DISLIKE_COMMENT',
  EDIT_COMMENT: 'EDIT_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  GET_MY_REPOST: 'GET_MY_REPOST',
  ADD_REPOST: 'ADD_REPOST',
  SEND_POST: 'SEND_POST',
  EDIT_REPOST: 'EDIT_REPOST',
  DELETE_REPOST: 'DELETE_REPOST',
  DELETE_SENT_POST: 'DELETE_SENT_POST',
  GET_STORY_LIKES: 'GET_STORY_LIKES',
  FETCH_STORY_LIKES: 'FETCH_STORY_LIKES',
  LIKE_STORY: 'LIKE_STORY',
  DISLIKE_STORY: 'DISLIKE_STORY',
  GET_STORY_DETAILS: 'GET_STORY_DETAILS',
  FETCH_COMMENT_LIKES: 'FETCH_COMMENT_LIKES',
  FETCH_REPOSTED_USERS_LIST: 'FETCH_REPOSTED_USERS_LIST',
  FETCH_WITH_WHOM_STORY_SHARED: 'FETCH_WITH_WHOM_STORY_SHARED',
  ADD_USER_DEVICE_TOKEN: 'ADD_USER_DEVICE_TOKEN',
  SUBSCRIBE_TO_ALERT: 'SUBSCRIBE_TO_ALERT',
  ADD_NEW_TRANSACTION: 'ADD_NEW_TRANSACTION',
  VALIDATE_TRANSACTION: 'VALIDATE_TRANSACTION',
  SET_REFERRER: 'SET_REFERRER',
  RECORD_PAGEVIEW: 'RECORD_PAGEVIEW',
  GET_PAGEVIEW_INFO: 'GET_PAGEVIEW_INFO',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
  SIGN_OUT_USER: 'SIGN_OUT_USER',
  FLAG_COMMENT: 'FLAG_COMMENT',
  BLOCK_USER: 'BLOCK_USER',
  PORTAL_LINK: 'PORTAL_LINK',
  DELETE_USER_ACCOUNT: 'DELETE_USER_ACCOUNT',
  GET_SUBSCRIBERS_PROGRESS: 'GET_SUBSCRIBERS_PROGRESS',
  GET_NAVIGATION: 'GET_NAVIGATION',
  REMOVE_PROFILE_IMAGE: 'REMOVE_PROFILE_IMAGE',
  SCRAPE_URL: 'SCRAPE_URL',
  UPDATE_LAST_SEEN_NOTIFICATION_AT: 'UPDATE_LAST_SEEN_NOTIFICATION_AT',
  ADD_EMAIL_FLOW_DATA: 'ADD_EMAIL_FLOW_DATA',
  SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS',
  HAS_COMIC_READ_ACCESS: 'HAS_COMIC_READ_ACCESS',
  UNFRIEND: 'UNFRIEND',
  GET_MY_FRIENDS: 'GET_MY_FRIENDS',
  GET_FRIENDSHIP_STATUS: 'GET_FRIENDSHIP_STATUS',
  GET_EPISODES: 'GET_EPISODES',
  GET_EMAIL_FLOW_DATA: 'GET_EMAIL_FLOW_DATA',
  GET_APP_CONFIG: 'GET_APP_CONFIG',
  ADD_LOG: 'ADD_LOG',
};

export const TINYVIEW_SHOP = 'https://shop.tinyview.com';

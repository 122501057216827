<div *ngIf="!isStoryPage" class="gift-container" [ngClass]="{'m-0': (isDirectoryPage || isSubscribePage), 'hide-border-bottom': !commentCount, 'render-on-top': renderOnTop}">
    <div class="images d-flex align-items-center" (click)="openGiftArtistModal(giftArtistModal)">
        <img *ngIf="!isLike" [src]="getImagePath('LIKE_ICON')" alt="like">
        <img *ngIf="isLike" [src]="getImagePath('RED_HEART')" alt="like">
        <ng-container *ngFor="let gift of reactionData">
            <img [src]="gift.imageSrc"
                *ngIf="gift.show"/>
        </ng-container>
        <span>
            <div class="body-mini">{{likeCount ? (likeCount | numberFormat) :'' }}</div>
        </span>
        <p class="body-mini" *ngIf="!likeCount">0</p>
    </div>
    <div>
        <a (click)="showALlComments(storyID, modal, commentModal, true)">
            <div class="comments-count d-flex">
                <img class="icon-20" [src]="getImagePath('ICONS8_COMMENT')" alt="comment-icon">
                <span class="body-mini">{{commentCount | numberFormat}}</span>
            </div>
        </a>
    </div>
    <div class="story-views">
        <div class="view-count d-flex">
            <img class="icon-20" [src]="getImagePath('ICON_EYE')" alt="view-icon">
            <span class="body-mini">{{viewCount | numberFormat}}</span>
        </div>
    </div>
    <div class="popup">
        <a (click)="openShareModal(shareModal)" class="d-flex align-items-center">
            <img class="icon-20 mr-0" [src]="getImagePath('ICONS8_UPLOAD_150')"  alt="like">
            <ng-container *ngIf="showShare">
                <div class="popuptext" id="myPopup">
                    <a (click)="shareFacebook()" class="fa">
                        <img [src]="getImagePath('ICONS8_FACEBOOK')">
                    </a>
                    <a (click)="shareTwitter()" class="fa">
                        <img [src]="getImagePath('ICONS8_TWITTERX')">
                    </a>
                </div>
            </ng-container>
        </a>
    </div>
</div>

<!-- <div *ngIf="false" class="bottom-section align-items-center">
    <a (click)="openGiftArtistModal(giftArtistModal)" class="d-flex align-items-center">
        <img class="icon-20" *ngIf="!isLike" [src]="getImagePath('LIKE_ICON')" alt="like">
        <img *ngIf="isLike" [src]="getImagePath('RED_HEART')" alt="like">&nbsp;
        <p *ngIf="!isLike">Like</p>
        <p *ngIf="isLike">Liked</p>
    </a>
    <a (click)="showALlComments(storyID, modal, commentModal, false)" class="d-flex align-items-center">
        <img class="icon-20" [src]="getImagePath('ICONS8_COMMENT')" alt="like" >&nbsp;
        <p>Comment</p>
    </a>
    <div class="popup">
        <a (click)="openShareModal(shareModal)" class="d-flex align-items-center">
            <img class="icon-20" [src]="getImagePath('ICONS8_SHARE')"  alt="like">
            <ng-container *ngIf="showShare">
                <div class="popuptext" id="myPopup">
                    <a (click)="shareFacebook()" class="fa">
                        <img [src]="getImagePath('ICONS8_FACEBOOK')">
                    </a>
                    <a (click)="shareTwitter()" class="fa">
                        <img [src]="getImagePath('ICONS8_TWITTERX')">
                    </a>
                </div>
            </ng-container> &nbsp;
            <p>Share</p>
        </a>
    </div>
</div> -->
<ng-container *ngIf="!isDirectoryPage && !isSubscribePage">
    <div *ngIf="isStoryPage" class="title-head p-bold ml-20" [ngClass]="{'mt-12': isStoryPage}">
        {{ commentCount }} comment{{ (commentCount != 1) ? 's' : '' }}
    </div>
    <app-comments [storyID]="storyID" [record]="record" [commentCount]="commentCount" [anonymousUser]="anonymousUser" 
        [storyData]="storyData" (updatedCommentCount)="updatedCommentCount($event)"></app-comments>
</ng-container>
<ng-template #modal>
    <div>
        <app-action-popup [modalRef]="modalRef" [ctaList]="ctaList" [likedComic]="likedComic"></app-action-popup>
    </div>
</ng-template>

<!-- UNUSED AS PER NEW REQUIREMENTS -->
<ng-template #giftModal>
    <app-gift-items [giftItemCount]="giftItemCount" [storyID]="storyID" [giftModalRef]="giftModalRef"> </app-gift-items>
</ng-template>

<ng-template #commentModal>
    <div class="action-container">
        <div class="text-center mb-2">
            <p>You need to complete your profile first.</p>
        </div>
        <div>
        <div class="btn-container pb-0">
            <button class="secondary-btn" aria-label="Open" (click)="commentModalRef.hide()">
                Cancel
            </button>
            &nbsp;
            <button class="btn tv-btn-red" aria-label="Open" (click)="goToSignInPage()">
                Edit Profile
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #giftArtistModal>
    <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="giftArtistModalRef.hide()" />
    <div class="heading">
        <p class="gift-artist-heading">Send love or more to the artist...</p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let gift of availableGifts">
            <div *ngIf="!gift.hide" class="gift-item" [ngClass]="{'disable-gift': alreadyGifted(gift)}" (click)="addReaction(gift.reactionName, gift.productId, gift.cost)">
                <img class="gift-img" src="{{ getImgUrl(gift) }}">
                <span class="gift-title">{{ getGiftTitle(gift) }}</span>
                <span class="gift-price">{{ gift.price }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
    <div *ngIf="isCheckoutURLLoading" class="loader-bar center-loader"></div>
</ng-template>

<ng-template #shareModal>
    <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="shareRef.hide()" />
    <div class="heading">
        <h2 class="share-heading" *ngIf="!isSubscriber || isSeriesCreator || actionType === 'website' || isShareTVPage">Share it with friends</h2>
        <h2 class="share-heading" *ngIf="isSubscriber && !isSeriesCreator && actionType !== 'website' && !isShareTVPage">Gift this comic</h2>
        <p class="share-sub-heading" *ngIf="!isSubscriber">and earn 1 influence point for each click</p>
        <p class="share-sub-heading" *ngIf="(isSubscriber && (isSeriesCreator ||actionType === 'website' || isShareTVPage))">and help creators by spreading the word</p>
        <p class="share-sub-heading" *ngIf="isSubscriber && !isSeriesCreator && actionType !== 'website' && !isShareTVPage">As a premium user, you can gift comics to anyone. They can read the full comic for FREE including any bonus panels.</p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let share of shareConfig">
            <div *ngIf="share.show" class="gift-item share-item" (click)="manualShare(share)">
                <img class="share-img" [src]="share.image">
                <span class="share-title">{{ share.title }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
</ng-template>

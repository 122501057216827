import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommentsComponent } from '../comments/comments.component';
import { AuthService } from '../services/auth.service';
import { ComicService } from '../services/comic.service';
import { StoryFeedService } from '../services/story-feed.service';
import * as util from '../utilities/common.util';
import { BROWSER, subscribeURL } from '../constants/common.constants';
import { LocalStorageService } from '../services/local-storage.service';
import { NewComicService } from '../new-comic.service';
import { Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { SessionStorageService } from '../services/session-storage.service';
import { getImage } from '../constants/images.constants';
import { AppService } from '../services/app.service';
const COMIC_IMG_API = `${environment.API_END_POINT}`;

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit, OnDestroy {
  @Input() commentCount: any;
  @Input() repostCount: any;
  @Input() viewCount: any;
  @Input() likeCount: any;
  @Input() storyID: any;
  @Input() storyData: any;
  @Input() isLike: string | boolean;
  @Input() comicAction: any;
  @Input() giftItemCount: any
  @Input() hideCommentSection: boolean
  @Input() giftedItems: any;
  @Input() actionType: any;
  @Input() record?: number;
  @Input() desc?: string;
  @Input() img?: string;
  @Input() refType?: string;
  @Input() title?: string;
  @Input() renderOnTop?: boolean;

  showComments: boolean = false;
  @ViewChild(CommentsComponent, { static: true }) comment: CommentsComponent;
  isCover: any;
  currentUrl: any;
  currentDevice: string;
  showShare: boolean = false;
  shareData = {
    title: 'Tinyview',
    text: '',
    url: '',
  };
  isDirectoryPage: boolean;
  modalRef: BsModalRef;
  giftModalRef: BsModalRef;
  anonymousUser: any;
  signUpModalRef: BsModalRef;
  commentArray: any = [];
  likedComic: any;
  chapterList: any = [];
  param: any;
  showHeart: boolean = false;
  isSubscribePage: boolean = false;
  userDetails: any;
  commentModalRef: BsModalRef;
  giftArtistModalRef: BsModalRef;
  public availableGifts;
  isCheckoutURLLoading: boolean;
  highestGift: any;
  secondHighestGift: any;
  // lowestGift: any;
  shareRef: BsModalRef;
  shareConfig: { title: string; action: string; image: string; show: boolean; }[];
  isDesktop: boolean;
  ctaList: Array<string>
  isAndroid: boolean;
  isWindows: boolean;
  isMobile: boolean;
  shareLink: any;
  reactionData = [];
  isSubscriber: any;
  giftItems: any;
  isStoryPage: boolean;
  isHomePage: boolean;
  seriesCreatorSubscription: any;
  isSeriesCreator: any;
  isShareTVPage: boolean;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private modalService: BsModalService,
    public feedService: StoryFeedService,
    @Inject(PLATFORM_ID) private platform: object,
    public authService: AuthService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private readonly comicService: ComicService,
    public newComicService: NewComicService,
    private appService: AppService,
    private meta: Meta
  ) {
    this.isMobile = this.newComicService.isMobileView()
    this.isCover = this.comicService.isCoverPage();
    this.currentUrl = this.comicService.getCurrentUrl();
    if (this.currentUrl == '/') {
      this.currentUrl = ''
    }
    this.currentDevice = this.comicService.getOperatingSystem();
    this.isDesktop = !!(this.currentDevice == BROWSER.DESKTOP);
    this.isAndroid = !!(this.currentDevice == BROWSER.ANDROID);
    this.isWindows = this.comicService.isWindows();
  }

  ngOnInit() {
    this.isShareTVPage = ['All Series', 'Subscribe', 'Tinyview Comics'].includes(this.title);
    this.isDirectoryPage = location.pathname.includes('/tinyview/comic-series-directory');
    this.isSubscribePage = this.currentUrl == subscribeURL;
    this.isStoryPage = this.comicService.isStoryPage();
    this.isHomePage = this.newComicService.isHomePage();
    
    this.prepareGifts();
    this.param = this.route.snapshot.paramMap.get('ID');
    if (isPlatformBrowser(this.platform)) {
      this.anonymousUser = util.isAnonymousUser();
    }
    if (!this.comicService.isCoverPage() && this.currentUrl != '' && this.currentUrl != '/' && !this.storyID) {
      this.isLike = this.comicService.userLikesArray ? this.comicService.userLikesArray.indexOf(this.comicAction) > -1 ? true : false : false;
    }
    this.shareConfig = this.comicService.getShareItems(this.isDesktop, this.isAndroid, this.isWindows);

    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;

    this.seriesCreatorSubscription = this.newComicService.whichSeriesCreator$.subscribe((value) => {
      this.isSeriesCreator = this.storyData && this.storyData.series === value;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe when the component is destroyed
    if (this.seriesCreatorSubscription) {
      this.seriesCreatorSubscription.unsubscribe();
    }
  }

  async prepareGifts () {
    this.giftItems = await this.appService.getGifts();
    if (this.giftItemCount) {
      this.highestGift = [...this.giftItems.map(item => item.reactionName)]
        .reverse()
        .find(gift => !!this.giftItemCount[gift] && gift !== 'LIKE');
      this.secondHighestGift = [...this.giftItems.map(item => item.reactionName)]
        .reverse()
        .find(gift => !!this.giftItemCount[gift] && ![this.highestGift, 'LIKE'].includes(gift));

      if (this.highestGift === this.secondHighestGift) {
        this.secondHighestGift = null;
      }
    }
   this.availableGifts = this.giftItems;
   this.refreshReactions();
  }

  public refreshReactions() {
    this.reactionData = [];
    this.giftItems.map(item => {
      if (item.reactionName === 'LIKE') {
        return; // skip heart icon as we will show it according to current user's heart reaction
      }
      const showGift = item.reactionName === 'LIKE' ? this.giftItemCount['LIKE'] > 0 : this.showGift(item.reactionName);
      const img = item.reactionName === 'LIKE' ? this.getImagePath("RED_HEART") : item.image;
      this.reactionData.push({
        reactionName: item.reactionName,
        imageSrc: this.comicService.getCDNImgUrl(img),
        show: showGift
      });
    });
  }

  public doLike(storyID: any) {
    if (isPlatformBrowser(this.platform)) {
      if (this.comicService.cacheComic[this.currentUrl]) {
        this.chapterList = this.comicService.cacheComic[this.currentUrl];
      }
    }
    if (!this.comicService.isCoverPage() && this.currentUrl != '' && this.currentUrl != '/' && !storyID) {
      if (this.isLike) {
        this.likeCount--;
        this.showHeart = false;
        this.isLike = false;
        this.giftItemCount['LIKE']--;
        this.refreshReactions();
        if (this.chapterList) {
          for (var i = 0; i < this.chapterList.length; i++) {
            if (this.chapterList[i].storyID == storyID) {
              this.chapterList[i].isLiked = false;
              this.chapterList[i].likeCount = this.chapterList[i].likeCount - 1
            }
          }
        }
        this.comicService.cacheComic[this.currentUrl] = this.chapterList
        this.comicService.addRemoveLike(this.comicAction);
      } else {
        this.likeCount++;
        this.isLike = true;
        this.showHeart = true;
        typeof (this.giftItemCount['LIKE']) === 'undefined' ? this.giftItemCount['LIKE'] = 1 : this.giftItemCount['LIKE']++;
        this.refreshReactions();
        if (this.chapterList) {
          for (var i = 0; i < this.chapterList.length; i++) {
            if (this.chapterList[i].storyID == storyID) {
              this.chapterList[i].isLiked = true;
              this.chapterList[i].likeCount = this.chapterList[i].likeCount + 1
            }
          }
        }
        this.comicService.cacheComic[this.currentUrl] = this.chapterList
        this.comicService.addRemoveLike(this.comicAction, true);
      }
    } else {
      if (this.isLike) {
        this.likeCount--;
        this.showHeart = false;
        this.isLike = false;
        this.giftItemCount['LIKE']--;
        this.refreshReactions();
        for (var i = 0; i < this.chapterList.length; i++) {
          if (this.chapterList[i].storyID == storyID) {
            this.chapterList[i].isLiked = false;
            this.chapterList[i].likeCount = this.chapterList[i].likeCount - 1
          }
        }
        this.comicService.cacheComic[this.currentUrl] = this.chapterList
        this.feedService.doDislikeStory(storyID);
      } else {
        this.likeCount++;
        this.isLike = true;
        this.showHeart = true;
        typeof (this.giftItemCount['LIKE']) === 'undefined' ? this.giftItemCount['LIKE'] = 1 : this.giftItemCount['LIKE']++;
        this.refreshReactions();
        for (var i = 0; i < this.chapterList.length; i++) {
          if (this.chapterList[i].storyID == storyID) {
            this.chapterList[i].isLiked = true;
            this.chapterList[i].likeCount = this.chapterList[i].likeCount + 1
          }
        }
        this.comicService.cacheComic[this.currentUrl] = this.chapterList
        this.feedService.doLikeStory(storyID)
      }
    }
    this.isCheckoutURLLoading = false;
  }
// Info: commented in html
  // onShare(comicAction: string) {
  //   let url;
  //   if (comicAction && this.isValidUrl(comicAction) && (comicAction.includes('http') || comicAction.includes('https://storage.googleapis.com/'))) {
  //     url = comicAction;
  //   } else {
  //     url = 'https://tinyview.com' + comicAction.replace('/index.json', '')
  //   }
  //   this.shareData = {
  //     title: 'Tinyview',
  //     text: this.comicService.toTitleFormat(/[^/]*$/.exec(comicAction.replace('/index.json', ''))[0]),
  //     url: url
  //   };
  //   if (this.currentDevice == BROWSER.ANDROID || this.currentDevice == BROWSER.IOS) {
  //     this.share();
  //   } else {
  //     this.showShare = !this.showShare;
  //   }
  // }

  // dupe
  public isValidUrl = urlString => {
    // SSR check
    if (typeof window === "undefined") {
      return '';
    }
    var a = document.createElement('a');
    a.href = urlString;
    return (a.host && a.host != window.location.host);
  }

  async share() {
    let newVariable: any;
    if (isPlatformBrowser(this.platform) && window && window.navigator) {
      newVariable = window.navigator;
      if (newVariable && newVariable.share) {
        try {
          await newVariable.share(this.shareData);
        } catch (err) {
        }
      }
    }
  }

  public shareFacebook(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(this.shareData.url);
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&utmSource=facebook&utmContent=referral&utmTerm=topbar&referrer=facebook`;
      this.comicService.popUpUrl(shareUrl);
    }
  }

  public shareTwitter(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(this.shareData.url);
      const shareUrl = `https://www.twitter.com/intent/tweet?url=${url}&via=TinyviewComics&utmSource=twitter&utmContent=referral&utmTerm=topbar&referrer=twitter`;
      this.comicService.popUpUrl(shareUrl);
    }
  }

  openModal(template: TemplateRef<any>, isLike) {
    this.likedComic = isLike;
    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
  }

  openGiftModal(template: TemplateRef<any>) {
    this.giftModalRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-md",
      // class: 'modal-xl',
      ignoreBackdropClick: true
    }));
  }

  openGiftArtistModal(template: TemplateRef<any>) {
    const isMobile = [BROWSER.IOS, BROWSER.ANDROID].includes(this.currentDevice) ? ' mobile-modal' : '';
    this.giftArtistModalRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: true,
      animation: !isMobile
    }));
  }

  openCommentModal(template: TemplateRef<any>) {
    this.commentModalRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-layout",
      // class: 'modal-xl',
      ignoreBackdropClick: true
    }));
  }

  public showALlComments(storyID, modal, commentModal, redirect) {
    this.ctaList = this.comicService.getCTAList();
    if (this.ctaList.length && !redirect) {
      if (this.ctaList.includes('signin')) this.openModal(modal, false);
      if (this.ctaList.includes('incompleteProfile')) this.openCommentModal(commentModal);
      if (this.ctaList.includes('subscribe')) this.openModal(modal, false);
      return;
    }
    this.comicService.getUserDetails().then(res => {
      this.userDetails = res.data;
      if (this.anonymousUser || this.ctaList.includes('incompleteProfile')) {
        var sp = window.scrollY;
        this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
        this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
        this.router.navigate(['story', storyID]);
      } else if (this.userDetails.userName && this.userDetails.gender) {
        if (isPlatformBrowser(this.platform)) {
          var sp = window.scrollY;
          this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
          this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
        }
        this.router.navigate(['story', storyID]);
      }
    });
  }

  public goToSignInPage() {
    this.router.navigate(['/edit-profile']).then(() => {
      this.commentModalRef.hide();
    })
  }

  public addReaction(reactionType: string, productID: string, value: number) {
    if (this.alreadyGifted({ reactionName: reactionType })) {
      return;
    }
    this.isCheckoutURLLoading = true;
    if (reactionType === 'LIKE') {
      this.doLike(this.storyID);
      this.giftArtistModalRef.hide();
    } else {
      this.comicService.getCheckoutURL(productID, this.storyID, reactionType, value).then((res) => {
        // this.isCheckoutURLLoading = false;
        const url = res.data.data.url;
        if (url) {
          window.open(url, "_self");
        }
        this.giftArtistModalRef.hide();
      })
        .catch(err => {
          this.isCheckoutURLLoading = false;
          this.giftArtistModalRef.hide();
        });
    }
  }

  public getImgUrl(gift: any): string {
    if (gift.reactionName === 'LIKE') { // According to COM-2503, we need to show filled when it is not liked and vice versa
      return !this.isLike ? this.getImagePath("HEART_OUTLINE") : this.getImagePath("HEART_OUTLINE_FILLED");
    }
    return this.comicService.getCDNImgUrl(gift.image);
  }

  public getGiftTitle(gift: any): string {
    if (gift.reactionName === 'LIKE') {
      return this.isLike ? 'Unlike' : 'Like';
    }
    return gift.title;
  }

  public alreadyGifted(gift: any): boolean {
    if (this.isCheckoutURLLoading) {
      return true;
    }
    if ((this.actionType === 'website' && gift.reactionName !== 'LIKE') || (this.refType === 'SENT' && gift.reactionName !== 'LIKE')) {
      return true;
    }
    if (this.giftedItems && this.giftedItems[gift.reactionName]) {
      return (gift.reactionName === 'LIKE') ? false : true;
    }
    return false;
  }

  public showGift(gift: string): boolean {
    if (!gift) {
      return false;
    }
    if (this.highestGift === gift || this.secondHighestGift === gift) {
      return true;
    };
    return false;
  }

  public manualShare(share) {
    this.comicService.shareConditions(this.shareLink, share.action, this.isDesktop, this.isAndroid);
    // this.comicService.manualShare(this.comicAction, this.storyID, share, this.isDesktop, this.isAndroid);
    this.shareRef && this.shareRef.hide();
  }

  async openShareModal(template: TemplateRef<any>) {
    let comicAction = this.storyData.action || (this.currentUrl === '/tinyview/comic-series-directory' ? `${this.currentUrl}/index.json` : this.currentUrl);
    let isWebsite: boolean = this.actionType === 'website';
    if (isWebsite) {
      comicAction = `/story/${this.storyID}/`;
    }

    if (this.storyData && this.storyData.image) {
      let metaTagsData = {};
      metaTagsData['og:title'] = this.storyData.title;
      metaTagsData['og:description'] = (this.storyData.comment || this.storyData.description);
      metaTagsData['og:image'] = this.storyData.image.indexOf(COMIC_IMG_API) > -1 ? this.storyData.image : `${COMIC_IMG_API}${this.storyData.image}`;

      // TODO(shashank): make it through params
      this.sessionStorageService.setItem('metaTagsInfo', metaTagsData);
    }

    const data = await this.comicService.getInfluenceData(comicAction, this.storyID, isWebsite, (this.isSubscriber && !this.isSeriesCreator));
    // Uncomment for share/invite debugging purpose
    // console.log('storyID', this.storyID);
    // console.log('actionURL', this.comicAction);
    // console.log('data', data);

    if (this.img.indexOf('.com') === -1) {
      this.img = `${COMIC_IMG_API}${this.img}`
    }
    const metaData = [{
      property: 'twitter:description',
      content: `${this.desc}`
    },
    {
      property: 'twitter:image',
      content: `${this.img}`
    },
    {
      property: 'twitter:title',
      content: `${this.title}`
    },
    {
      property: 'og:title',
      content: `${this.title}`
    },
    {
      property: 'og:description',
      content: `${this.desc}`
    },
    {
      property: 'og:image',
      content: `${this.img}`
    },
    {
      property: 'og:url',
      content: `https://tinyview.com${comicAction}`
    },
    ];
    for (const metaProperty of metaData) {
      this.meta.updateTag({
        property: metaProperty.property,
        content: metaProperty.content
      });
    }

    window['branch'].link(data, (err, link) => {
      this.shareLink = link;
      const isMobile = [BROWSER.IOS, BROWSER.ANDROID].includes(this.currentDevice) ? ' mobile-modal' : '';
      this.shareRef = this.modalService.show(template, Object.assign({}, {
        class: `modal-md${isMobile}`,
        ignoreBackdropClick: false,
        animation: !isMobile
      }));
    });
  }

  public updatedCommentCount(count) {
    this.commentCount = count;
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}

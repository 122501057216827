import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComicComponent } from './comic/comic.component';
import { StoreModule } from '@ngrx/store';
import { Reducer } from './redux/reducer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CookieService } from 'ngx-cookie-service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComicNavComponent } from './comic-nav/comic-nav.component';
import { AppMaterialModule } from './app-material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropzoneDirective } from './shared/directives/dropzone.directive';
import { ComicDirectoryComponent } from './comic-directory/comic-directory.component';
import { ComicStoryComponent } from './comic-story/comic-story.component';
import { PanelNavComponent } from './panel-nav/panel-nav.component';
import { ComicService } from './services/comic.service';
import { AsyncPipe, DatePipe } from '@angular/common';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NavButtonsComponent } from './nav-buttons/nav-buttons.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { NavComponent } from './nav/nav.component';
import { ComicEpisodesComponent } from './comic-episodes/comic-episodes.component';
import { TableVirtualScrollModule } from 'ng-cdk-table-virtual-scroll';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AngularDeviceInformationService } from 'angular-device-information';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PaymentsComponent } from './payments/payments.component';
import { CheckSubcriptionComponent } from './check-subcription/check-subcription.component';
import { LongPressDirective } from './shared/directives/long-press.directive';
import { GiftItemsModalComponent } from './gift-items-modal/gift-items-modal.component';
import { GiftPaymentCompletionComponent } from './gift-payment-completion/gift-payment-completion.component';
import { ToastrModule } from 'ngx-toastr';
import { ManageInfluenceComponent } from './manage-influence/manage-influence.component';
import { InfluenceCardComponent } from './influence-card/influence-card.component';
import { ClipboardModule } from 'ngx-clipboard';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TvHeaderComponent } from './components/tv-header/tv-header.component';
import { ComicWrapperComponent } from './pages/comic-wrapper/comic-wrapper.component';
import { BottomNavMobileComponent } from './components/bottom-nav-mobile/bottom-nav-mobile.component';
import { SeeFriendsComponent } from './components/see-friends/see-friends.component';
import { FriendRequestComponent } from './components/friend-request/friend-request.component';
import { TemplateCardComponent } from './template-card/template-card.component';
import { InviteFriendsComponent } from './invite-friends/invite-friends.component';
import { initializeApp } from './app-initializer';
import { SignupComponent } from './signup/signup.component';
import { DobComponent } from './components/dob/dob.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SigninComponent } from './signin/signin.component';
import { NewUserComponent } from './new-user/new-user.component';
import { NumericOnlyDirective } from './shared/directives/numeric-only.directive';
import { ScrollerComponent } from './components/scroller/scroller.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { CreatorsDashboardModule } from './creators-dashboard/creators-dashboard.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ComicNavigationComponent } from './comic-navigation/comic-navigation.component';
import { EmailLoginComponent } from './components/email-login/email-login.component';
import { EmailLandingPageComponent } from './components/email-landing-page/email-landing-page.component';
import { ManageAlertsComponent } from './manage-alerts/manage-alerts.component';
import { NgToggleModule } from 'ng-toggle-button';
import { OtpComponent } from './components/otp/otp.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { SigninSuccessComponent } from './components/signin-success/signin-success.component';
import { LoggedOutModalComponent } from './logged-out-modal/logged-out-modal.component';
import { ChannelVerificationComponent } from './channel-verification/channel-verification.component';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { ActionSheetModalComponent } from './action-sheet-modal/action-sheet-modal.component';
import { FollowModalComponent } from './components/follow-modal/follow-modal.component';
import { AppService } from './services/app.service';
import { ApiService } from './services/api.service';
import { LoggerService } from './services/logger.service';
import { RssFeedComponent } from './rss-feed/rss-feed.component';

const configuration = environment.config;

@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    ComicNavComponent,
    ProgressBarComponent,
    DropzoneDirective,
    ComicComponent,
    ComicDirectoryComponent,
    ComicStoryComponent,
    PanelNavComponent,
    NavButtonsComponent,
    NavComponent,
    ChannelVerificationComponent,
    ComicEpisodesComponent,
    DobComponent,
    SigninSuccessComponent,
    OtpComponent,
    PhoneNumberComponent,
    EmailLoginComponent,
    EmailLandingPageComponent,
    ProfileComponent,
    PaymentsComponent,
    CheckSubcriptionComponent,
    LongPressDirective,
    NumericOnlyDirective,
    GiftItemsModalComponent,
    GiftPaymentCompletionComponent,
    ManageInfluenceComponent,
    InfluenceCardComponent,
    NotificationsComponent,
    FollowModalComponent,
    TopBannerComponent,
    SeeFriendsComponent,
    FriendRequestComponent,
    SidebarComponent,
    ScrollerComponent,
    ListViewComponent,
    BottomNavMobileComponent,
    TvHeaderComponent,
    ComicWrapperComponent,
    TemplateCardComponent,
    InviteFriendsComponent,
    SignupComponent,
    SigninComponent,
    NewUserComponent,
    EditProfileComponent,
    ComicNavigationComponent,
    ManageAlertsComponent,
    LoggedOutModalComponent,
    ActionSheetModalComponent,
    RssFeedComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(configuration),
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AppMaterialModule,
    SharedModuleModule,
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({ appId: 'devServerApp' }),
    DragDropModule,
    FormsModule,
    NgToggleModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    ScrollDispatchModule,
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    TableVirtualScrollModule,
    InfiniteScrollModule,
    ClipboardModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    StoreModule.forRoot({
      post: Reducer
    }),
    ToastrModule.forRoot(),
    CreatorsDashboardModule,
    ImageCropperModule,
  ],
  entryComponents: [
    LoggedOutModalComponent,
    ActionSheetModalComponent,
    FollowModalComponent
  ],
  providers: [
    CookieService,
    AuthService,
    ComicService,
    AppService,
    ApiService,
    LoggerService,
    AsyncPipe,
    DatePipe,
    AngularDeviceInformationService,
    // ModalService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

<div (appOutsideClick)=outsideClick($event) [@slideInOut]="menuState" class="w-nav-overlay" [ngClass]="{'menu': true}">
  <nav role="navigation" class="nav-menu w-nav-menu nav-menu-bg default-shadow">
    <app-menu [userPhoneNumber]="userPhoneNumber" [userImage]="userImage" [userName]="userName"
      [isIncompleteProfile]="isIncompleteProfile" [subDetails]="subDetails"
      [userBadges]="userBadges" [isSubscribed]="isSubscribed" (parentCall)="parentCall()" (toggleMenu)="toggleMenu()"
      (openInviteFriends)="openInviteFriendsModal(inviteFriendsModal)"></app-menu>
  </nav>
</div>

<app-panel-nav *ngIf="((isComicPage && !isSubscribePage) || isStoryPage) && panelData?.giftsCounts"
  (doLike)="onDoLike($event)"
  [isLiked]="panelData?.isLiked" 
  [commentCount]="panelData?.commentCount" 
  [viewCount]="panelData?.viewCount" 
  [likeCount]="panelData?.likeCount"
  [storyID]="panelData?.storyID" 
  [giftedItems]="panelData?.giftedItems" 
  [giftItemCount]="panelData?.giftsCounts" 
  [isBottomCard]="true" 
  [storyData]="panelData?.storyData" 
  [hideBottomSection]="true" 
  [actionType]="panelData?.actionType">
</app-panel-nav>

<div *ngIf="isHomePage" class="nav-container sub-bottom-bar px-20">
  <div>
    <a class="light-text" (click)="navigateTo('home', $event)" [ngClass]="{'selected': activeIcon === ''}">
      All
    </a>
    <a class="light-text" (click)="navigateTo('tickbox', $event)" [ngClass]="{'selected': activeIcon === '/following'}">
      Following
    </a>
  </div>

  <div class="right-links">
    <a *ngIf="!userDetailsLoading" class="plan-text" (click)="navigateTo('subscribe', $event)">
      <img *ngIf="subscriptionBadge" [src]="subscriptionBadge" class="plan-img">
      {{ subscriptionBadge ? 'Premium Plan' : 'Free Plan' }}
    </a>
    <a *ngIf="isAnonymous" class="sign-in-text" (click)="navigateTo('sign-in', $event)">
      Sign In
    </a>
  </div>
</div>


<!-- HOME PAGE AND ALL OTHER PAGES -->
<div *ngIf="!isSeriesPage && !isComicPage && !isStoryPage && isMobile"
  class="justify-content-between nav-container d-flex px-2">
  <a *ngIf="!isHomePage" class="px-3" (click)="navigateTo('back', $event)">
    <img class="icons back" [src]="getIconUrl('back')" alt="back-icon">
  </a>
  <a (click)="navigateTo('homeOrFollowing', $event)" class="px-3">
    <img class="icons" [src]="getIconUrl('home')" alt="home-icon">
  </a>
  <a (click)="navigateTo('shop', $event)" class="px-3">
    <img class="icons" [src]="getIconUrl('shop')" alt="following-icon">
  </a>
  <a (click)="navigateTo('directory', $event)" class="px-3">
    <img class="icons" [src]="getIconUrl('directory')" alt="directory-icon">
  </a>
  <a (click)="navigateTo('notification')" [ngClass]="{'position-relative': (menuState === 'out')}" class="px-3">
    <div class="circle count" *ngIf="unreadCount && unreadCount != '0' && (menuState === 'out')" [class.oval]="unreadCount === '99+'">
      <span class="number">{{unreadCount}}</span>
    </div>
    <img class="icons" [src]="getIconUrl('/notifications')" alt="notification-icon">
  </a>
  <a (appOutsideClick)=outsideClick($event) class="toggle-btn pt-0" (click)="toggleMenu()" class="px-3">
    <img *ngIf="isIncompleteProfile && (menuState === 'out')" [src]="getImagePath('ICONS8_BOX_IMPORTANT_100_LOGO_RED')" width="16px"
      height="16px" class="not-icon ml-2">
    <img class="icons" [src]="getIconUrl('/account')" alt="user-icon">
  </a>
</div>

<!-- SERIES PAGE BOTTOM NAV -->
<div *ngIf="isSeriesPage" class="nav-container series-nav">
  <div class="d-flex justify-content-between">
    <a (click)="navigateTo('back', $event)" class="pr-3 mr-2">
      <img class="icons back" [src]="getIconUrl('back')" alt="back-icon">
    </a>
    <!-- Loader button when either userDetailsLoading or followBtnLoading is true -->
    <button *ngIf="userDetailsLoading || followBtnLoading" class="btn tv-btn-red follow-btn loader-btn cursor-not-allowed">
      <div class="loader-bar-btn mx-4"></div>
    </button>

    <!-- Follow button when nothing is loading and the user is not following the series -->
    <button *ngIf="!userDetailsLoading && !isUserFollowingCurrentSeries && !followBtnLoading" class="btn tv-btn-red follow-btn"
      (click)="navigateTo('follow', $event)">
      <p class="body-mini">+ Follow</p>
    </button>

    <button *ngIf="!userDetailsLoading && isUserFollowingCurrentSeries && continueReadingData?.isContinueReading && !followBtnLoading"
      class="btn tv-btn-dark continue-reading-btn"
      [ngClass]="{'position-relative': (menuState === 'out')}"
      (click)="navigateToNextComic(continueReadingData)">
      <p class="body-mini">{{episodeBtnCta}}</p>
      <div class="circle comic-count" *ngIf="continueReadingData?.unReadCount && (menuState === 'out')"
        [class.oval]="continueReadingData?.unReadCount > 99">
        <span class="number">{{ continueReadingData?.unReadCount > 99 ? '99+' : continueReadingData?.unReadCount }}
        </span>
      </div>
    </button>

    <button *ngIf="!userDetailsLoading && isUserFollowingCurrentSeries && !continueReadingData?.isContinueReading && !followBtnLoading"
      class="btn tv-btn-dark no-comic-btn cursor-not-allowed">
      <p class="body-mini">Next Episode</p>
    </button>
  </div>

  <div class="d-flex justify-content-between">
    <a *ngIf="isMobile" class="px-3" (click)="navigateTo('homeOrFollowing', $event)">
      <img class="icons" [src]="getIconUrl('home')" alt="home-icon">
    </a>

    <a *ngIf="isMobile" class="px-3" (click)="navigateTo('directory', $event)">
      <img class="icons" [src]="getIconUrl('directory')" alt="directory-icon">
    </a>
    <a (click)="navigateTo('share', $event)" [ngClass]="{ 'desktop-share': !isMobile, 'px-3': isMobile }">
      <img class="icons" [ngClass]="{ 'icon-22 mr-1': !isMobile }" [src]="getIconUrl('share')" alt="share-icon">
      <p *ngIf="!isMobile">Share</p>
    </a>
    <a *ngIf="isMobile" (appOutsideClick)=outsideClick($event) class="toggle-btn pt-0 pl-3" (click)="toggleMenu()">
      <img *ngIf="isIncompleteProfile && (menuState === 'out')" [src]="getImagePath('ICONS8_BOX_IMPORTANT_100_LOGO_RED')" width="16px"
        height="16px" class="not-icon-series-nav ml-2">
      <img class="icons" [src]="getIconUrl('/account')" alt="user-icon">
    </a>
  </div>
</div>

<!-- COMIC PAGE BOTTOM NAV -->
<div *ngIf="isComicPage" class="nav-container series-nav">
  <div class="d-flex justify-content-between">
    <a (click)="navigateTo('back', $event)" class="pr-3 mr-2">
      <img class="comic-icons back" [src]="getIconUrl('back')" alt="back-icon">
    </a>
    <!-- Loader button when either userDetailsLoading or followBtnLoading is true -->
    <button *ngIf="userDetailsLoading || followBtnLoading" class="btn tv-btn-red follow-btn loader-btn cursor-not-allowed">
      <div class="loader-bar-btn mx-4"></div>
    </button>

    <!-- Follow button when nothing is loading and the user is not following the series -->
    <button *ngIf="!userDetailsLoading && !isUserFollowingCurrentSeries && !followBtnLoading" class="btn tv-btn-red follow-btn"
      (click)="navigateTo('follow', $event)">
      <p class="body-mini">+ Follow</p>
    </button>
    <a *ngIf="!userDetailsLoading && isUserFollowingCurrentSeries" (click)="navigateTo('homeOrFollowing', $event)"
      class="pr-3 mr-3">
      <img class="comic-icons" [src]="getIconUrl('home')" alt="home-icon">
    </a>

    <!-- Notification icon when user is following -->
    <a *ngIf="!userDetailsLoading && isUserFollowingCurrentSeries && isMobile" (click)="navigateTo('notification')">
      <div class="circle count-comic" *ngIf="unreadCount && unreadCount != '0'" [class.oval]="unreadCount === '99+'">
        <span class="number">{{unreadCount}}</span>
      </div>
      <img class="comic-icons icon-24" [src]="getIconUrl('/notifications')" alt="notification-icon">
    </a>
  </div>

  <!-- Home icon when user is not following -->
  <a *ngIf="!isUserFollowingCurrentSeries" (click)="navigateTo('homeOrFollowing', $event)" class="mx-auto px-3">
    <img class="comic-icons" [src]="getIconUrl('home')" alt="home-icon">
  </a>

  <div class="d-flex justify-content-between">
    <a (click)="navigateTo('previous-comic', $event)" class="pr-3" [ngClass]="{'disable-comic-nav-btn': disableBtn.prevComic}">
      <img class="comic-icons" [src]="getIconUrl('previous-comic')" alt="previous-comic-icon">
    </a>
    <a (click)="navigateTo('series-home', $event)" class="px-3">
      <img class="comic-icons" [src]="getIconUrl('series-home')" alt="series-home-icon">
    </a>
    <a (click)="navigateTo('next-comic', $event)" class="pl-3" [ngClass]="{'disable-comic-nav-btn': disableBtn.nextComic}">
      <img class="comic-icons" [src]="getIconUrl('next-comic')" alt="next-comic-icon">
    </a>
  </div>
</div>

<!-- STORY PAGE BOTTOM NAV -->
<div *ngIf="isStoryPage" class="nav-container series-nav">
  <div class="d-flex justify-content-between">
    <a (click)="navigateTo('back', $event)" class="pr-3 mr-1">
      <img class="comic-icons back" [src]="getIconUrl('back')" alt="back-icon">
    </a>
    <button *ngIf="!isExternalLink" (click)="navigateTo('view-comic', $event)"
      class="btn tv-btn-dark continue-reading-btn">
      <p class="body-mini">View Comic</p>
    </button>
    <button *ngIf="isExternalLink" (click)="navigateTo('open-link', $event)"
      class="btn tv-btn-dark continue-reading-btn d-flex align-items-center">
      <img [src]="getImagePath('ICONS8_EXTERNAL_LINK_LIGHT_100')" width="16px" height="16px" class="mr-1" alt="External Link">
      <p class="body-mini">{{externalBtnCta}}</p>
    </button>
  </div>

  <a (click)="navigateTo('homeOrFollowing', $event)" class="mx-auto px-3">
    <img class="comic-icons" [src]="getIconUrl('home')" alt="home-icon">
  </a>

  <div class="d-flex justify-content-between">
    <a (click)="navigateTo('previous-comic', $event)" class="pr-3" [ngClass]="{'disable-comic-nav-btn': disableBtn.prevComic}">
      <img class="comic-icons" [src]="getIconUrl('previous-comic')" alt="previous-comic-icon">
    </a>
    <a (click)="navigateTo('series-home', $event)" class="px-2 mx-1">
      <img class="comic-icons" [src]="getIconUrl('series-home')" alt="series-home-icon">
    </a>
    <a (click)="navigateTo('next-comic', $event)" class="pl-3" [ngClass]="{'disable-comic-nav-btn': disableBtn.nextComic}">
      <img class="comic-icons" [src]="getIconUrl('next-comic')" alt="next-comic-icon">
    </a>
  </div>
</div>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="isSubscribed" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>